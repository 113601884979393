/*
 *  ======================================= 
 *  HOME PAGE | _home_page
 *  =======================================  
 */

.homepage {
    &-header {
        height: 100vh;
        position: relative;
        z-index: 0;

        &-background {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: -1;
        }

        &-bottom {
            bottom: 40px;
            position: absolute;
            text-align: center;
            width: 100%;
        }

        &-button {
            animation: scrolldown 2s infinite;
            background-color: $orange;
            border-radius: 50%;
            color: $white;
            display: inline-block;
            height: 45px;
            padding-top: 12px;
            transition: background-color .3s ease, color .3s ease;
            width: 45px;

            &:hover {
                background-color: darken($orange, 10%);
                color: darken($white, 10%);
                transition: background-color .3s ease, color .3s ease;
            }
        }
    }

    &-aboutus {
        background-color: $blue;
        color: $white;

        &-text {
            color: $grey;
            margin-bottom: 110px;

            ul, p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &-row {
            margin-left: -5px;
            margin-right: -5px;

            [class*="col-"] {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        &-asset {
            margin-bottom: 100px;
            position: relative;

            .arc {
                display: inline-block;
                position: relative;
                vertical-align: middle;
            }

            i {
                color: $blue2;
                font-size: 170px;
            }

            .number {
                color: $orange;
                font-size: 50px;
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
            }

            .text {
                color: $white;
                display: inline-block;
                font-size: 20px;
                line-height: 120%;
                margin-left: 30px;
                vertical-align: middle;

                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-offer {
        background-color: transparent;
        color: $blue;

        &-title {
            &:before, &:after {
                background-color: $blue;
                height: 1.5px;
            }
        }

        &-background, &-background2 {
            background-attachment: fixed;
            background-position: 50% 0;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            height: 400px;
            width: 100%;
            z-index: -1;
        }

        &-section {
            float: left;
            padding-top: 90px;
            padding-bottom: 90px;
            width: 100%;

            &:first-child {
                padding-top: 0;
                padding-bottom: 50px;
            }

            &.darkerbackground {
                background-color: $blue;

                .homepage-offer-box .title, .homepage-offer-box .text, .homepage-offer-box .bottombutton {
                    padding-left: 0;
                    padding-right: 40px;
                }

                .homepage-offer-box .title {
                    color: $white;
                }

                .homepage-offer-box .text {
                    color: rgba($white, 0.5);
                }
            }
        }

        &-image {
            display: block;
            height: 412px;
            position: relative;

            &.nolink {
                .divimage {
                    opacity: 1.0 !important;
                }

                i {
                    color: rgba($white, 0) !important;
                }
            }

            &:hover {
                .divimage {
                    opacity: 0.7;
                    transition: opacity .3s ease;
                }

                i {
                    color: rgba($white, 1);
                    transition: color .3s ease .1s;
                }
            }

            .divimage {
                opacity: 1;
                transition: opacity .3s ease;
            }

            i {
                color: rgba($white, 0);
                font-size: 75px;
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                transition: color .3s ease;
                z-index: 1;
            }
        }

        &-box {
            padding-bottom: 60px;

            .title {
                color: $blue;
                display: inline-block;
                font-size: 40px;
                margin-top: 5px;
                margin-bottom: 35px;
                padding-left: 40px;
            }

            .text {
                color: rgba($blue, 0.9);
                font-size: 20px;
                padding-left: 40px;

                p, ul {
                    margin-bottom: 30px;
                }
            }

            .bottombutton {
                bottom: 0;
                padding-left: 40px;
                position: absolute;
            }
        }
    }

    &-stock {
        background-color: $white;
        color: $blue;

        &-background {
            background-attachment: fixed;
            background-position: 50% 0;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            height: 400px;
            width: 100%;
            z-index: -1;
        }

        &-title {
            &:before, &:after {
                background-color: $blue;
                height: 1.5px;
            }
        }

        &-searchform {
            display: inline-block;
            height: 50px;
            margin-bottom: 50px;
            max-width: 100%;
            position: relative;
            width: 665px;

            i {
                color: $blue2;
                font-size: 20px;
                left: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            input {
                border: 1px solid rgba($black, 0.2);
                border-radius: 20px;
                height: 50px;
                padding-left: 60px;
                padding-right: 145px;
                transition: border-color .3s ease;
                width: 100%;

                &:focus {
                    border-color: rgba($blue, 0.6);
                    transition: border-color .3s ease;
                }
            }

            button {
                //background-color: $orange;
                border: 0;
                border-radius: 20px;
                color: $white;
                height: 50px;
                -webkit-mask-image: -webkit-radial-gradient(white, black);
                overflow: hidden;
                position: absolute;
                right: 0;
                text-transform: uppercase;
                transition: background-color .3s ease, color .3s ease;
                top: 0;
                width: 135px;
                z-index: 0;

                &:before {
                    background-color: $orange;
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: -2;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: -75px;
                    bottom: 0;
                    left: 0;
                    border-right: 25px solid transparent;
                    border-bottom: 50px solid $blue;
                    transform: translateX(-100%);
                    transition: .5s ease-in-out;
                    z-index: -1;
                }

                &:hover {
                    //background-color: $blue2;
                    color: $white;
                    transition: background-color .3s ease, color .3s ease;

                    &:after {
                        transform: translateX(0);
                    }
                }
            }
        }

        &-filters {
            margin-top: 45px;
            margin-bottom: 30px;

            &.fixed {
                background: $white;
                box-shadow: 0px 0px 5px 0 rgba(0,0,0,0.5);
                margin: 0 auto;
                padding: 25px 0 0;
                position: fixed;
                transition: top .3s ease;
                width: 100%;
                z-index: 901;

                &.lowertop {
                    top: 100px;
                }
            }

            .row {
                margin-left: -10px;
                margin-right: -10px;
            }

            .filtercolumn {
                padding-left: 10px;
                padding-right: 10px;
            }

            .bootstrap-select {
                margin-bottom: 20px;
            }

            .bootstrap-select.open {
                position: relative;
                z-index: 802;
            }

            .bootstrap-select .dropdown, .dropdown {
                margin-bottom: 20px;

                &.open {
                    .dropdown-toggle {
                        position: relative;
                        z-index: 801;
                    }
                }

                &-toggle {
                    border-radius: 20px;
                    padding: 9px 12px;
                    width: 100%;
                    z-index: 801 !important;

                    &:focus {
                        outline: 0 !important;
                    }

                    .filter-option {
                        width: calc(100% - 12px) !important;
                    }
                }

                &-header {
                    padding: 3px 12px !important;
                }

                &-menu {
                    //padding-top: 20px;
                    //top: calc(100% - 20px);
                    //width: 100%;
                    > li > a {
                        padding-left: 12px !important;
                    }
                }
            }

            .filter {
                &-title {
                    font-size: 16px;
                    margin-bottom: 0;
                    padding-left: 13px;
                    text-transform: uppercase;
                }

                &-input {
                    border: 1px solid #ccc;
                    border-radius: 20px;
                    color: #333;
                    background-color: #fff;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 1.42857143;
                    margin-bottom: 20px;
                    padding: 9px 12px;
                    transition: border .3s ease;
                    width: 100%;

                    &:focus {
                        border-color: $black;
                    }
                }

                &-fullwidthbutton {
                    margin-bottom: 20px;
                    min-width: initial;
                    width: 100%;

                    &.no-margin{
                        margin-bottom: 0;
                    }

                    i {
                        font-size: 12px;
                        margin-left: 5px;
                    }
                }

                &-clearfilter {
                    display: inline-block;
                    font-size: 13px;
                    margin-bottom: 4px;
                    text-align: center;
                    width: 100%;

                    i {
                        margin-left: -5px;
                        vertical-align: middle;
                    }
                }

                &-options {
                    float: left;
                    width: 100%;
                }

                &-searchform {
                    display: inline-block;
                    height: 40px;
                    margin-bottom: 20px;
                    max-width: 100%;
                    position: relative;
                    width: 100%;

                    input {
                        border: 1px solid rgba($black, 0.2);
                        border-radius: 20px;
                        font-size: 14px;
                        height: 40px;
                        padding-left: 15px;
                        padding-right: 15px;
                        transition: border-color .3s ease;
                        width: 100%;

                        &:focus {
                            border-color: rgba($blue, 0.6);
                            transition: border-color .3s ease;
                        }
                    }

                    button {
                        //background-color: $orange;
                        border: 0;
                        border-radius: 20px;
                        color: $white;
                        font-size: 14px;
                        height: 40px;
                        overflow: hidden;
                        position: absolute;
                        right: 0;
                        text-transform: uppercase;
                        transition: background-color .3s ease, color .3s ease;
                        top: 0;
                        width: 40px;
                        z-index: 0;

                        i {
                            vertical-align: text-bottom;
                        }

                        &:before {
                            background-color: $orange;
                            content: '';
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            z-index: -2;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            right: -75px;
                            bottom: 0;
                            left: 0;
                            border-right: 25px solid transparent;
                            border-bottom: 50px solid $blue;
                            transform: translateX(-100%);
                            transition: .5s ease-in-out;
                            z-index: -1;
                        }

                        &:hover {
                            //background-color: $blue2;
                            color: $white;
                            transition: background-color .3s ease, color .3s ease;

                            &:after {
                                transform: translateX(0);
                            }
                        }
                    }
                }
            }

            .bootstrap-tagsinput {
                align-items: center;
                display: inline-flex;
                overflow: hidden;
                text-align: right;
                white-space: nowrap;

                border: 1px solid #ccc;
                border-radius: 20px;
                color: #333;
                background-color: #fff;
                font-size: 14px;
                font-weight: 400;
                line-height: 1.42857143;
                margin-bottom: 20px;
                padding: 9px 12px;
                transition: border .3s ease;
                width: 100%;

                &:focus {
                    border-color: $black;
                }

                span {
                    display: none !important;
                }
            }

            .toggle-filters {
                margin-bottom: 20px;

                span {
                    padding: 0;
                }
            }

            .title {
                display: inline-block;
                text-align: left;
                width: calc(100% - 8px);
            }
        }

        &-categories {
            &.extrapadding {
                padding-top: 110px;
            }

            .row {
                margin-left: -10px;
                margin-right: -10px;
            }

            .categorycolumn {
                display: -webkit-flex;
                margin-bottom: 20px;
                padding-left: 10px;
                padding-right: 10px;
            }

            .categorybox {
                background-color: rgba($grey2, 0.2);
                float: left;
                height: 100%;
                padding-left: 15px;
                padding-right: 15px;
                width: 100%;

                &.bigger {
                    padding-left: 20px;
                    padding-right: 20px;

                    .video-title {
                        font-size: 20px;
                    }

                    .video-caption {
                        top: 0;
                    }

                    .video-details i {
                        font-size: 20px;
                        margin-top: 4px;
                        position: relative;
                        //vertical-align: bottom;
                    }

                    .video-time {
                        margin-right: 15px;
                    }

                    .video-resolution {
                        //margin-left: 15px;
                    }

                    .video-time-value, .video-resolution-value {
                        font-size: 20px;
                    }
                }
            }

            .title {
                color: $blue3;
                display: block;
                font-size: 24px;
                padding-top: 20px;
                text-align: center;
                text-decoration: underline;

                &:hover {
                    color: $orange;
                }
            }

            .video {
                &-box {
                    float: left;
                    margin-bottom: 8px;
                    margin-top: 15px;
                    width: 100%;

                    &:not(.loaded) {
                        .video-button, .video-details {
                            opacity: 0;
                            pointer-events: none;
                        }
                    }
                }

                &-image {
                    display: block;
                    margin-bottom: 10px;
                    overflow: hidden;
                    position: relative;

                    &:hover {
                        .more {
                            color: rgba($white, 1);
                            transition: color .3s ease .1s;
                        }

                        .image {
                            opacity: 0.7;
                            transform: scale(1.2);
                        }
                    }

                    .more {
                        color: rgba($white, 0);
                        font-size: 20px;
                        left: 50%;
                        position: absolute;
                        text-transform: uppercase;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        transition: color .3s ease;
                        z-index: 1;
                    }

                    .image {
                        background-position: center center;
                        background-repeat: no-repeat;
                        background-size: cover;
                        transition: all .3s ease;
                        position: absolute;
                        height: 100%;
                        width: 100%;

                        &.lazyload {
                            opacity: 0;

                            &.loaded {
                                opacity: 1;
                            }
                        }

                        .lazyload-trigger {
                            visibility: hidden;
                        }
                    }

                    .ratio {
                        background-color: $black;
                        background-image: url(/frontend/img/loader.gif);
                        background-position: center center;
                        background-size: auto;
                        background-repeat: no-repeat;
                        padding-bottom: 55%;
                    }
                }

                &-caption {
                    float: left;
                    position: relative;
                    top: 2px;
                    width: 100%;
                }

                &-title {
                    color: lighten($black, 30%);
                    float: left;
                    font-size: 13px;
                }

                &-details {
                    float: right;
                    transition: opacity .9s ease;

                    i {
                        color: $orange;
                        float: left;
                        margin-right: 5px;
                    }
                }

                &-time {
                    display: inline-block;
                    float: left;
                    margin-right: 10px;

                    &-value {
                        color: $black;
                        float: left;
                        font-size: 13px;
                        //vertical-align: text-bottom;
                    }
                }

                &-resolution {
                    display: inline-block;
                    float: left;

                    &-value {
                        color: $black;
                        float: left;
                        font-size: 13px;
                        //vertical-align: text-bottom;
                    }
                }

                &-button {
                    float: left;
                    margin-top: 0;
                    margin-left: 5px;
                    transition: opacity .6s ease;
                    width: auto;

                    a {
                        color: $blue;
                        height: auto;
                        padding: 0;
                        top: -2px;
                        width: auto;

                        i {
                            transition: .2s ease-in-out;
                        }

                        &:before {
                            background: none;
                            border: none;
                        }

                        &:after {
                            display: none;
                        }

                        &:hover {
                            i {
                                font-size: 18px;
                                color: $orange;
                            }

                            &:after {
                                background: none;
                                border: none;
                            }
                        }
                    }
                }
            }
        }

        &-button {
            padding-top: 30px;
            padding-bottom: 50px;
        }
    }

    &-realizations {
        background-color: $blue;
        color: $white;

        &-holder {
            position: relative;
        }

        &-carousel {
            .active.center {
                .item {
                    opacity: 1;
                    transition: opacity .3s ease;
                }

                .box {
                    pointer-events: all;
                }

                i {
                    color: rgba($orange, 1);
                    text-shadow: 0px 0px 20px rgba($black, 0.4);
                    transition: color .3s ease, text-shadow .3s ease;
                }

                .caption {
                    background-color: $white;
                    color: #010101;
                    transition: background-color .3s ease, color .3s ease;
                }
            }

            .item {
                opacity: 0.5;
                transition: opacity .3s ease;
            }

            .box {
                display: block;
                pointer-events: none;
                position: relative;

                &:hover {
                    .image {
                        opacity: 0.7;
                        transition: opacity .3s ease;
                    }

                    i {
                        color: rgba($white, 1);
                        transition: color .3s ease;
                    }
                }
            }

            i {
                color: rgba($orange, 0);
                font-size: 85px;
                left: 50%;
                position: absolute;
                text-shadow: 0px 0px 20px rgba($black, 0);
                top: 50%;
                transform: translate(-50%, -50%);
                transition: color .3s ease .2s, text-shadow .3s ease .2s;
                z-index: 1;
            }

            .image {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                position: absolute;
                transition: opacity .3s ease;
                height: 100%;
                width: 100%;
            }

            .ratio {
                padding-bottom: 56.25%;
            }

            .caption {
                background-color: rgba($white, 0);
                color: $white;
                font-size: 20px;
                height: 60px;
                padding: 16px 0;
                text-align: center;
                transition: background-color .3s ease, color .3s ease;
            }
        }

        &-controls {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            pointer-events: none;
            width: 100%;
            z-index: 2;

            i {
                background-color: $blue2;
                color: $white;
                display: inline-block;
                font-size: 21px;
                padding: 24.5px 30px;
            }

            .owl-prev, .owl-next {
                background-color: transparent;
                border: 0;
                cursor: pointer;
                padding: 0;
                pointer-events: all;
                position: absolute;
                top: calc(50% - 30px);
                transform: translateY(-50%);
            }

            .owl-prev {
                left: 0px;

                i {
                    border-top-right-radius: 35px;
                    border-bottom-right-radius: 35px;
                    padding-right: 50px;
                    transition: background-color .3s ease, color .3s ease, padding .3s ease;

                    &:hover {
                        background-color: darken($blue2, 10%);
                        color: $orange;
                        padding-left: 45px;
                        transition: background-color .3s ease, color .3s ease, padding .3s ease;
                    }
                }
            }

            .owl-next {
                right: 0px;

                i {
                    border-top-left-radius: 35px;
                    border-bottom-left-radius: 35px;
                    padding-left: 50px;
                    transition: background-color .3s ease, color .3s ease, padding .3s ease;

                    &:hover {
                        background-color: darken($blue2, 10%);
                        color: $orange;
                        padding-right: 45px;
                        transition: background-color .3s ease, color .3s ease, padding .3s ease;
                    }
                }
            }
        }

        &-dots {
            margin: 30px 0;
            pointer-events: all;
            text-align: center;

            &-holder {
                left: 0;
                pointer-events: none;
                position: absolute;
                top: 0;
                height: 100%;
                width: 100%;
                z-index: 2;
            }

            .owl-dot {
                background-color: transparent;
                border: 0;
                cursor: pointer;
                display: inline-block;
                padding: 0;
                zoom: 1;

                span {
                    background-color: rgba($orange, 0);
                    border: 3px solid $white;
                    width: 15px;
                    height: 15px;
                    margin: 20px 5px 20px 5px;
                    display: block;
                    -webkit-backface-visibility: visible;
                    transition: all .3s ease;
                    border-radius: 50%;
                }

                &.active span, &:hover span {
                    background-color: $orange;
                    border: 3px solid $orange;
                    border-radius: 50%;
                    transition: all .3s ease;
                }
            }
        }

        &-logos {
            background-color: $white;
            box-shadow: 0px 1px 1px 0px rgba($black, 0.2);
            color: $blue;
            position: relative;
            z-index: 1;

            &-title {
                &:before, &:after {
                    background-color: $blue;
                    height: 1.5px;
                }
            }

            &-holder {
                margin-bottom: 60px;
                position: relative;
            }

            &-carousel {
                //padding: 0 90px;
                .box {
                    position: relative;
                }

                .owl-stage {
                    transition-timing-function: linear !important;
                }

                .owl-item {
                    height: 80px;
                }

                .item {
                    height: 100%;
                    position: relative;
                }

                img {
                    display: inline-block !important;
                    image-rendering: pixelated;
                    left: 50%;
                    max-width: 100%;
                    position: absolute;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: auto !important;
                }

                .image {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    position: absolute;
                    height: 100%;
                    width: 100%;
                }

                .ratio {
                    padding-bottom: 35%;
                }
            }

            &-controls {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                pointer-events: none;
                width: 100%;
                z-index: 2;

                &.disabled {
                    display: none;
                }

                i {
                    color: $orange;
                    display: inline-block;
                    font-size: 41px;
                    padding: 24.5px 30px;
                    transition: color .3s ease;

                    &:hover {
                        color: $blue;
                        transition: color .3s ease;
                    }
                }

                .owl-prev, .owl-next {
                    background-color: transparent;
                    border: 0;
                    cursor: pointer;
                    padding: 0;
                    pointer-events: all;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }

                .owl-prev {
                    left: 0px;

                }

                .owl-next {
                    right: 0px;
                }
            }
        }

        &-map {
            background-color: $white;
            height: 540px;
            position: relative;

            .image {
                background-position: center center;
                background-repeat: no-repeat;
                background-size: cover;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }

            .caption {
                bottom: 35px;
                color: $blue;
                font-size: 30px;
                padding: 0 15px;
                position: absolute;
                text-align: center;
                text-transform: uppercase;
                width: 100%;
                z-index: 1;
            }
        }
    }

    &-contact {
        background-color: $blue2;
        color: $white;

        &-box {
            height: 180px;
            position: relative;

            &:before, &:after {
                background-color: rgba($white, 0.3);
                content: '';
                height: 100%;
                position: absolute;
                width: 1px;
            }

            &:before {
                left: 0;
            }

            &:after {
                right: 0;
            }

            &:first-child {
                &:before {
                    display: none;
                }
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }

            .centering {
                left: 15px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: calc(100% - 30px);
            }

            .phonelinks {
                display: inline-block;
                margin-left: 32px;
                vertical-align: middle;
            }

            .icon-telefon {
                color: rgba($white, 0.5);
                font-size: 44px;
                vertical-align: middle;
            }

            .phonelink {
                color: $white;
                display: block;
                font-size: 32px;
                //margin-left: 32px;
                vertical-align: middle;

                &:hover {
                    color: rgba($white, 0.5);
                }
            }

            .icon-email {
                color: rgba($white, 0.5);
                font-size: 44px;
                vertical-align: middle;
            }

            .emaillink {
                color: $white;
                font-size: 24px;
                margin-left: 30px;
                vertical-align: middle;

                &:hover {
                    color: rgba($white, 0.5);
                }
            }

            .medialink {
                display: inline-block;
                margin-left: 60px;

                i {
                    color: rgba($white, 0.5);
                    font-size: 44px;
                    transition: color .3s ease;
                }

                &:hover {
                    i {
                        color: rgba($white, 1);
                        transition: color .3s ease;
                    }
                }
            }
        }
    }
}

@keyframes scrolldown {
    0% {
        transform: translate(0%, 0px);
        -webkit-transform: translate(0%, 0px);
    }
    50% {
        transform: translate(0%, -20px);
        -webkit-transform: translate(0%, -20px);
    }
    100% {
        transform: translate(0%, 0px);
        -webkit-transform: translate(0%, 0px);
    }
}

_:-moz-tree-row(hover), .homepage-stock-categories .categorybox {
    line-height: 1.2;
    padding-bottom: 3px;
}

_:-moz-tree-row(hover), .homepage-stock-categories .categorybox.bigger {
    line-height: 1.42857143;
    padding-bottom: 0;
}