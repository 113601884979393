/*
 *  ======================================= 
 *  SUBPAGE | _subpage
 *  =======================================  
 */

.subpage {
    background-color: $blue2;
    color: $white;
    min-height: calc(100vh - 120px);
    padding-top: 185px;

    &-spacer {
        background-color: $black;
        height: 135px;

        &.smaller {
            height: 100px;
            transition: background-color .3s ease, height .3s ease;
        }
    }

    &-nopadding {
        //border-bottom: 2px solid rgba($white, 0.5);
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    &-margin {
        margin-top: 80px;
        margin-bottom: 80px;
    }

    &-darkerbackground {
        background-color: $blue;
        left: 0;
        top: 0;
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: -1;
    }

    &-bottomspacer {
        height: 45px;
    }

    &-title {
        &:before, &:after {
            background-color: $blue;
            height: 1.5px;
        }
    }

    &-carousel {
        &-holder {
            position: relative;
        }

        .active.center {
            .item {
                opacity: 1;
                transition: opacity .3s ease;
            }

            .box {
                pointer-events: all;
            }

            i {
                color: rgba($orange, 1);
                text-shadow: 0px 0px 20px rgba($black, 0.4);
                transition: color .3s ease, text-shadow .3s ease;
            }

            .caption {
                background-color: $white;
                color: #010101;
                transition: background-color .3s ease, color .3s ease;
            }
        }

        i {
            color: rgba($white, 0);
            font-size: 85px;
            left: 50%;
            position: absolute;
            text-shadow: 0px 0px 20px rgba($black, 0);
            top: 50%;
            transform: translate(-50%, -50%);
            transition: color .3s ease .2s, text-shadow .3s ease .2s;
            z-index: 1;
        }

        .gallery-image {
            transition: opacity .3s ease;
        }

        .magnific-popup:hover {
            .gallery-image {
                opacity: 0.8;
                transition: opacity .3s ease;
            }

            i {
                color: rgba($white, 1);
                text-shadow: 0px 0px 20px rgba($black, 1);
                transition: color .3s ease .2s, text-shadow .3s ease .2s;
            }
        }

        .image {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: absolute;
            transition: opacity .3s ease;
            height: 100%;
            width: 100%;
        }

        .ratio {
            padding-bottom: 76%;
        }

        .caption {
            background-color: rgba($white, 0);
            color: $white;
            font-size: 20px;
            height: 60px;
            padding: 16px 0;
            text-align: center;
            transition: background-color .3s ease, color .3s ease;
        }

        &-controls {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            pointer-events: none;
            width: 100%;
            z-index: 2;

            i {
                background-color: $blue2;
                color: $white;
                display: inline-block;
                font-size: 21px;
                padding: 24.5px 30px;
            }

            .owl-prev, .owl-next {
                background-color: transparent;
                border: 0;
                cursor: pointer;
                padding: 0;
                pointer-events: all;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            .owl-prev {
                left: 0px;

                i {
                    border-top-right-radius: 35px;
                    border-bottom-right-radius: 35px;
                    padding-right: 50px;
                    transition: background-color .3s ease, color .3s ease, padding .3s ease;

                    &:hover {
                        background-color: darken($blue2, 10%);
                        color: $orange;
                        padding-left: 45px;
                        transition: background-color .3s ease, color .3s ease, padding .3s ease;
                    }
                }
            }

            .owl-next {
                right: 0px;

                i {
                    border-top-left-radius: 35px;
                    border-bottom-left-radius: 35px;
                    padding-left: 50px;
                    transition: background-color .3s ease, color .3s ease, padding .3s ease;

                    &:hover {
                        background-color: darken($blue2, 10%);
                        color: $orange;
                        padding-right: 45px;
                        transition: background-color .3s ease, color .3s ease, padding .3s ease;
                    }
                }
            }
        }
    }
}