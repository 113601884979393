/*
 *  ======================================= 
 *  COLORS | _colors
 *  =======================================  
 */

$black: #000000;
$white: white;
$orange: #eb6e24;
$blue: #252e48;
$blue2: #567793;
$blue3: #091a54;
$grey: #c2c2c2;
$grey2: #8f8f8f;

.color{
	&-black{
		color: $black;
	}
	&-white{
		color: $white;
	}
	&-orange{
		color: $orange;
	}
	&-blue{
		color: $blue;
	}
	&-blue2{
		color: $blue2;
	}
	&-blue3{
		color: $blue3;
	}
	&-grey{
		color: $grey;
	}
	&-grey2{
		color: $grey2;
	}
}