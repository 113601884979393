/*
 *  ======================================= 
 *  ORDER | _order
 *  =======================================  
 */

.order{
	&-header{
		&-title{
			color: $orange;
			max-width: 100% !important;
			padding: 45px 0 45px;
			text-align: left;
			&:before, &:after{
				display: none;
			}
		}
	}
	
	&-main{
		&-title{
			font-size: 20px;
			margin-bottom: 20px;
		}
		&-form{
			margin-bottom: 40px;
			.is-sending{
				position: relative;
				pointer-events: none;
				//opacity: 0.5;
				//transition: opacity .3s ease;
				&:before{
					background: rgba($white, 0.5);
					content: '';
					height: 100%;
					position: absolute;
					top: 0;
					transition: background .3s ease;
					width: 100%;
					z-index: 850;
				}
				&:after{
					background: $blue;
					border-radius: 20px;
					color: $white;
					content: 'Trwa wysyłanie formularza. Proszę czekać...';
					height: 45px;
					padding: 10.5px 20px;
					position: absolute;
					text-align: center;
					top: 50%;
					transform: translateY(-50%);
					width: 100%;
					z-index: 851;
				}
				&-en{
					&:after{
						content: 'The order form is sending, please wait...';
					}
				}
			}
			input[type='text'], textarea{
				border: 1px solid #ccc;
				border-radius: 20px;
				color: #333;
				background-color: #fff;
				font-size: 14px;
				font-weight: 400;
				line-height: 1.42857143;
				margin-bottom: 20px;
				padding: 9px 12px;
				resize: vertical;
				transition: border-color .3s ease;
				width: 100%;
				&.border-orange{
					border-color: $orange;
				}
				&:focus{
					border-color: rgba($blue, 0.6);
					transition: border-color .3s ease;
				}
			}
			label{
				cursor: pointer;
				font-weight: 400;
				margin-bottom: 20px;
				user-select: none;
				width: 100%;
				&.lower-margin{
					margin-bottom: 10px;
				}
			}
			input[type='checkbox']{
				display: inline-block;
				vertical-align: top;
			}
			.checkbox-text{
				display: inline-block;
				font-size: 14px;
				font-style: italic;
				transition: color .3s ease;
				width: calc(100% - 30px);
				&.text-orange{
					color: $orange;
				}
				&:hover{
					//color: $orange;
				}
				a{
					color: $orange;
					text-decoration: underline;
				}
			}
			.bootstrap-select{
				margin-bottom: 20px;
				width: 100% !important;
			}
			.bootstrap-select.open{
				position: relative;
				z-index: 802;
			}
			.bootstrap-select .dropdown, .dropdown{
				margin-bottom: 20px;
				&.open{
					.dropdown-toggle{
						position: relative;
						z-index: 801;
					}
				}
				&-toggle{
					border-radius: 20px;
					padding: 9px 12px;
					width: 100%;
					z-index: 801 !important;
					&:focus{
						outline: 0 !important;
					}
					.filter-option{
						width: calc(100% - 12px) !important;
					}
				}
				&-header{
					padding: 3px 12px !important;
				}
				&-menu{
					//padding-top: 20px;
					//top: calc(100% - 20px);
					//width: 100%;
					>li>a{
						padding-left: 12px !important;
					}
				}
			}
		}
		&-agreements{
			margin-top: 10px;
		}
		&-list{
			float: left;
			margin-bottom: 20px;
			width: 100%;
			.video{
				&-box{
					border-bottom: 1px solid $grey;
					float: left;
					margin-bottom: 20px;
					padding-bottom: 20px;
					width: 100%;
					&:last-child{
						border-bottom: 0;
					}
				}
				&-image{
					border: 1px solid $grey;
					display: block;
					float: left;
					overflow: hidden;
					position: relative;
					width: 25%;
					&:hover{
						.image{
							opacity: 0.7;
							transform: scale(1.2);
						}
					}
					.image{
						background-position: center center;
						background-repeat: no-repeat;
						background-size: cover;
						transition: all .3s ease;
						position: absolute;
						height: 100%;
						width: 100%;
					}
					.ratio{
						background-color: $black;
						padding-bottom: 55%;
					}
				}
				&-caption{
					display: block;
					float: left;
					padding-left: 15px;
					width: 75%;
				}
				&-title{
					color: $orange;
					float: left;
					font-size: 16px;
					margin-bottom: 10px;
					&:hover{
						color: $black;
					}
				}
				&-details{
					float: left;
					margin-bottom: 10px;
					width: 100%;
					i{
						color: $orange;
						margin-right: 2px;
					}
				}
				&-time{
					display: inline-block;
					&-value{
						color: $black;
						font-size: 16px;
						vertical-align: text-bottom;
					}
				}
				&-resolution{
					display: inline-block;
					margin-left: 6px;
					&-value{
						color: $black;
						font-size: 16px;
						vertical-align: text-bottom;
					}
				}
				&-description{
					display: none;
					float: left;
					margin-bottom: 10px;
					width: 100%;
				}
				&-price{
					color: $black;
					float: right;
					font-size: 16px;
					margin-bottom: 10px;
					text-align: right;
					//width: 100%;
				}
				&-comment{
					float: left;
					font-size: 16px;
					font-style: italic;
					text-align: justify;
					width: 100%;
				}
				&-licence{
					float: left;
					font-size: 16px;
					text-align: left;
					width: 100%;
					.title{
						margin-bottom: 5px;
					}
					input[type=checkbox], input[type=radio]{
						left: 0;
    				position: absolute;
					}
					label{
						cursor: pointer;
						font-weight: 400;
						padding-left: 20px;
						position: relative;
						user-select: none;
						width: 100%;
					}
				}
			}
		}
	}
}