@font-face {
  font-family: 'unit80';
  src:  url('../fonts/unit80.eot?brtgvi');
  src:  url('../fonts/unit80.eot?brtgvi#iefix') format('embedded-opentype'),
    url('../fonts/unit80.ttf?brtgvi') format('truetype'),
    url('../fonts/unit80.woff?brtgvi') format('woff'),
    url('../fonts/unit80.svg?brtgvi#unit80') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'unit80' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-chevron_lewo:before {
  content: "\e900";
}
.icon-chevron_prawo:before {
  content: "\e901";
}
.icon-chevron_gora:before {
  content: "\e915";
}
.icon-strzalka_lewo:before {
  content: "\e902";
}
.icon-strzalka_prawo:before {
  content: "\e903";
}
.icon-strzalka_dol:before {
  content: "\e904";
}
.icon-strzalka_gora:before {
  content: "\e905";
}
.icon-szukaj:before {
  content: "\e906";
}
.icon-play:before {
  content: "\e907";
}
.icon-czas:before {
  content: "\e908";
}
.icon-email:before {
  content: "\e909";
}
.icon-fb:before {
  content: "\e90a";
}
.icon-instagram:before {
  content: "\e90b";
}
.icon-kolo:before {
  content: "\e90c";
}
.icon-koszyk:before {
  content: "\e90d";
}
.icon-koszyk_2:before {
  content: "\e90e";
}
.icon-logo_unit_80:before {
  content: "\e90f";
}
.icon-rozdzielczosc:before {
  content: "\e910";
}
.icon-telefon:before {
  content: "\e911";
}
.icon-twitter:before {
  content: "\e912";
}
.icon-vimeo:before {
  content: "\e913";
}
.icon-youtube:before {
  content: "\e914";
}
