/*
 *  ======================================= 
 *  GLOBAL | _global
 *  =======================================  
 */
// Wstaw tutaj link do dodatkowych czcionek (np. Google Font)
@import url('https://fonts.googleapis.com/css?family=Asap:400,500,700&subset=latin-ext');

html, body {
    color: $black;
    font-family: 'Asap', sans-serif;
    font-size: 16px;
    font-weight: 400;
    min-width: 320px;
    height: 100vh;
}

a, .btn, button, input[type="button"], input[type="submit"], textarea:focus, input:focus {
    outline: none !important;
    box-shadow: unset !important;
}

a {
    color: #999;
    transition: color .3s ease;

    &:hover, &:focus, &:active {
        color: #666;
        text-decoration: none;
        transition: color .3s ease;
    }
}

ul:not([class]) {
    list-style-type: none;
    padding-left: 0;

    li {
        padding-left: 20px;
        position: relative;

        &:before {
            color: #666;
            content: "•";
            position: absolute;
            left: 0;
            transform: scale(1.2);
        }
    }
}

ol:not([class]) {
    list-style-type: none;
    padding-left: 0;

    li {
        counter-increment: step-counter;
        padding-left: 20px;
        position: relative;

        &:before {
            content: counter(step-counter) '.';
            position: absolute;
            left: 0;
        }
    }
}

.cl {
    clear: both;
    width: 100%;
    height: 1px;
    line-height: 1px;
    display: block;
}

.image-to-left {
    float: left;
    margin: 0 20px 15px 0;
}

.image-to-right {
    float: right;
    margin: 0 0 15px 20px;
}

.layout-container {
    padding-left: 0;
    padding-right: 0;
}

.cke_editable {
    padding: 15px 30px;
}

.cke_widget_wrapper {
    margin-left: -15px;
    margin-right: -15px;
}

.cke_widget_element {
    padding-left: 15px;
    padding-right: 15px;
}

table {
    thead, tbody {
        th, td {
            padding: 5px 10px;
        }
    }
}

.modal {
    z-index: 3000;

    &-body {
        a {
            color: $orange;
        }

        .default-button {
            color: $white;
        }

        .more-folders {
            margin-bottom: 20px;
        }

        .video-frame .resolutions {
            margin-top: 0px;
        }
    }

    &-footer {
        button, a {
            vertical-align: middle;
        }

        button + a, a + a, a + button {
            margin-bottom: 0;
            margin-left: 5px;
        }
    }

    .bootstrap-select {
        margin-bottom: 20px;
    }

    .bootstrap-select.open {
        position: relative;
        z-index: 1002;
    }

    .bootstrap-select .dropdown, .dropdown {
        margin-bottom: 20px;

        &.open {
            .dropdown-toggle {
                position: relative;
                z-index: 1001;
            }
        }

        &-toggle {
            border-radius: 20px;
            padding: 9px 12px;
            width: 100%;
            z-index: 1001 !important;

            &:focus {
                outline: 0 !important;
            }

            .filter-option {
                width: calc(100% - 12px) !important;
            }
        }

        &-menu {
            //padding-top: 20px;
            //top: calc(100% - 20px);
            //width: 100%;
            > li > a {
                padding-left: 12px !important;
            }
        }
    }
}

//TEXT


//BUTTONS

.default {
    &-button {
        border: 0;
        border-radius: 20px;
        color: $white;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        //height: 40px;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        min-width: 185px;
        overflow: hidden;
        padding: 9px 0;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        transition: background-color .3s ease, color .3s ease;
        z-index: 0;

        span {
            padding: 0 34px;
        }

        &:before {
            background-color: $blue;
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -2;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -50px;
            bottom: 0;
            left: 0;
            border-right: 20px solid transparent;
            border-bottom: 40px solid $orange;
            transform: translateX(-100%);
            transition: .5s ease-in-out;
            z-index: -1;
        }

        &:hover {
            color: $white;
            transition: background-color .3s ease, color .3s ease;

            &:after {
                transform: translateX(0);
            }
        }

        &-white {
            border: 1px solid $grey;
            box-shadow: 0px 0px 1px 0px black !important;
            color: $black;

            &:before {
                background-color: $white;
            }

            &:after {
                border-bottom: 40px solid $grey;
            }

            &:hover {
                color: $black;
            }
        }

        &-lightblue {
            &:before {
                background-color: $blue2;
            }
        }

        &-fluidwidth {
            min-width: initial;
            padding: 9px 12px;
            white-space: nowrap;
            width: auto;
        }

        &-fullwidth {
            min-width: initial;
            padding: 9px 12px;
            white-space: nowrap;
            width: 100%;
            vertical-align: inherit;
        }

        &-nopointer {
            cursor: default;
        }
    }

    &-transparentbutton {
        border: 0;
        border-radius: 20px;
        color: $blue;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        //height: 40px;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        min-width: 185px;
        overflow: hidden;
        padding: 9px 0;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        transition: background-color .3s ease, color .3s ease;
        z-index: 0;

        &:hover {
            color: $orange;
        }

        span {
            padding: 0 34px;
        }
    }

    &-circlebutton {
        border: 0;
        border-radius: 50%;
        color: $white;
        display: inline-block;
        height: 34px;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        overflow: hidden;
        padding: 6px 0;
        position: relative;
        text-align: center;
        transition: background-color .3s ease, color .3s ease;
        width: 34px;
        z-index: 0;

        &:before {
            background-color: #ccc;
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -2;
        }

        &:after {
            content: '';
            position: absolute;
            top: 0;
            right: -50px;
            bottom: 0;
            left: 0;
            border-right: 20px solid transparent;
            border-bottom: 40px solid $orange;
            transform: translateX(-100%);
            transition: .5s ease-in-out;
            z-index: -1;
        }

        &:hover {
            //background-color: $orange;
            color: $white;
            transition: background-color .3s ease, color .3s ease;

            &:after {
                transform: translateX(0);
            }
        }

        &-red {
            &:before {
                background-color: red;
            }
        }

        &-blue {
            &:before {
                background-color: $blue;
            }
        }
    }
}

//OTHER GLOBAL CLASSES

.global {
    &-flexcontent {
        display: flex;
        flex-wrap: wrap;

        &:before, &:after {
            display: none;
        }
    }

    &-idscroll {
        height: 100px;
        position: absolute;
        pointer-events: none;
        top: -100px;
        width: 100%;
    }

    &-float--100 {
        float: left;
        width: 100%;
    }

    &-clear {
        clear: both;
    }

    &-title {
        display: inline-block;
        font-size: 50px;
        line-height: 1;
        padding: 45px 20px;
        position: relative;
        text-align: center;
        text-transform: uppercase;

        &:before, &:after {
            background-color: $white;
            content: '';
            height: 1px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 139px;
        }

        &:before {
            right: 100%;
        }

        &:after {
            left: 100%;
        }
    }

    &-toggle-fixed {
        border-radius: 50%;
        bottom: 30px;
        display: none;
        font-size: 0;
        margin-bottom: 0;
        min-width: initial;
        padding: 13px 0;
        position: fixed;
        right: 30px;
        text-align: center;
        transition: opacity .3s ease;
        width: 50px;
        z-index: 10;

        i {
            font-size: 24px;
            margin-left: 0;
        }

        &:after {
            border-bottom: 50px solid #eb6e24;
        }
    }
}

//HAMBURGER

.hamburger {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    padding: 15px 15px;
    //display: inline-block;
    display: none;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {
        opacity: 0.7;
        transition-property: opacity;
    }

    &-box {
        width: 40px;
        height: 24px;
        display: inline-block;
        position: relative;
    }

    &-inner {
        display: block;
        top: 50%;
        margin-top: -2px;
    }

    &-inner, &-inner:before, &-inner:after {
        width: 40px;
        height: 4px;
        background-color: $white;
        border-radius: 4px;
        //box-shadow: 0px 0px 8px 0px rgba($white, 0.4) !important;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
    }

    &-inner:before, &-inner:after {
        content: "";
        display: block;
    }

    &-inner:before {
        top: -10px;
    }

    &-inner:after {
        bottom: -10px;
    }
}

.hamburger--collapse .hamburger-inner {
    top: auto;
    bottom: 0;
    transition-duration: 0.13s;
    transition-delay: 0.13s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse .hamburger-inner::after {
    top: -20px;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}

.hamburger--collapse .hamburger-inner::before {
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--collapse.is-active .hamburger-inner {
    transform: translate3d(0, -10px, 0) rotate(-45deg);
    transition-delay: 0.22s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}

.hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    transform: rotate(-90deg);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

//MENU
.header {
    background-color: rgba($black, 0.3);
    height: 135px;
    position: fixed;
    transition: background-color .3s ease, height .3s ease;
    width: 100vw;
    z-index: 901;

    &-blackbackgroundsubpage {
        background-color: rgba($black, 1);
        height: 135px;
        position: fixed;
        transition: background-color .3s ease, height .3s ease;
        width: 100vw;
        z-index: 900;

        &.smaller {
            height: 100px;
            transition: background-color .3s ease, height .3s ease;
        }
    }

    &.blackbackground {
        background-color: rgba($black, 1);
        transition: background-color .3s ease, height .3s ease;
        height: 100px;

        .header-logo {
            height: 100px;
        }

        .header-logo-image {
            height: 75px;
            transition: height .3s ease;
        }

        .header-menu-desktop > li > a {
            height: 100px;
            padding: 37px 20px 37px;
        }

        .header-menu-desktop > li > ul li a {
            background-color: rgba($black, 1);
        }
    }

    &-logo {
        display: inline-block;
        height: 135px;
        position: relative;

        &-link {
            display: inline-block;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            transition: opacity .3s ease;

            &.aside-active {
                opacity: 0;
                transition: opacity .3s ease;
            }
        }

        &-image {
            height: 90px;
            transition: height .3s ease;
            width: auto;
        }
    }

    &-menu {
        &-desktop {
            display: inline-block;
            padding: 0;
            list-style-type: none;
            margin-bottom: 0;
            position: relative;
            text-align: right;

            &:after {
                content: '';
                position: absolute;
                background-color: rgba(60, 59, 58, 0.6);
                right: -0.5px;
                height: 40px;
                top: 50%;
                transform: translateY(-50%);
                width: 1px;
            }

            li {
                display: inline-block;
                position: relative;

                a {
                    color: $white;
                    font-size: 18.5px;
                    font-weight: 700;
                    display: block;
                    height: 135px;
                    position: relative;
                    padding: 55px 20px 55px;
                    transition: height .3s ease, color .3s ease, transform .3s ease;

                    &:before {
                        background-color: $orange;
                        bottom: 0;
                        content: '';
                        height: 0px;
                        left: 0;
                        position: absolute;
                        transition: height .2s linear;
                        width: 100%;
                    }

                    /*
                    &:hover{
                        color: $orange;
                        &:before{
                            height: 5px;
                        }
                    }
                    */
                }

                ul {
                    display: block;
                    padding-left: 0;
                    position: absolute;
                    left: 0;
                    top: 100%;
                    z-index: 1;

                    li {
                        overflow: hidden;
                        height: 0;
                        transition: height 200ms ease-in;
                        padding-left: 0;
                        display: block;

                        &:before {
                            display: none;
                        }

                        a {
                            background-color: rgba($black, 1);
                            color: $white;
                            font-weight: 400;
                            height: 45px;
                            padding-top: 13.5px;
                            padding-bottom: 13.5px;
                            text-align: left;
                            white-space: nowrap;
                        }
                    }
                }

                &:hover {
                    > a {
                        color: $orange;

                        &:before {
                            height: 5px;
                        }
                    }

                    > ul {
                        li {
                            height: 45px;

                            a {
                                &:hover, &:focus {
                                    color: $orange;
                                }
                            }

                            &.active {
                                > a {
                                    color: $orange;
                                }
                            }
                        }
                    }
                }

                &:last-child {
                    &:after {
                        display: none;
                    }
                }

                &.active {
                    &:before {
                        content: '';
                        position: absolute;
                        background-color: rgba(60, 59, 58, 0.2);
                        left: -0.5px;
                        height: 40px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        background-color: rgba(60, 59, 58, 0.2);
                        right: -0.5px;
                        height: 40px;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                    }

                    > a {
                        color: $orange;

                        &:before {
                            height: 5px;
                        }
                    }
                }
            }
        }
    }

    &-cart {
        color: $white;
        display: inline-block;
        padding: 0 20px;
        position: relative;
        transition: none;
        vertical-align: middle;

        &:hover {
            transition: none;

            .header-cart-icon {
                color: $orange;
                transition: all .3s ease;
            }

            .header-cart-counter {
                background-color: $orange;
                transition: all .3s ease;
            }
        }

        &:after {
            content: '';
            position: absolute;
            background-color: rgba(60, 59, 58, 0.6);
            right: -0.5px;
            height: 40px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
        }

        &-icon {
            font-size: 28px;
            margin: 0 5px;
            transition: all .3s ease;
        }

        &-counter {
            background-color: $white;
            border-radius: 50%;
            color: $black;
            display: inline-block;
            font-size: 18.5px;
            height: 30px;
            margin: 0 5px;
            padding-top: 2px;
            text-align: center;
            transition: all .3s ease;
            width: 30px;
            vertical-align: top;
        }
    }

    &-languages {
        display: inline-block;
        position: relative;
        vertical-align: middle;

        &-link {
            border: 1px solid $white;
            border-radius: 20px;
            color: $white;
            display: block;
            font-size: 13px;
            height: 40px;
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            margin-left: 20px;
            overflow: hidden;
            padding: 10px 18.25px;
            position: relative;
            text-align: center;
            transition: background-color .3s ease, border-color .3s ease, color .3s ease;
            width: 55px;
            z-index: 0;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: -50px;
                bottom: 0;
                left: 0;
                border-right: 20px solid transparent;
                border-bottom: 40px solid $orange;
                transform: translateX(-100%);
                transition: .5s ease-in-out;
                z-index: -1;
            }

            &:hover {
                //background-color: $orange;
                border-color: transparent;
                color: $white;
                transition: background-color .3s ease, border-color .3s ease, color .3s ease;

                &:after {
                    transform: translateX(0);
                }
            }
        }
    }
}

@keyframes hover-link {
    0% {
        transform: translateY(0%);
    }
    49% {
        opacity: 0;
        transform: translateY(50%);
    }
    50% {
        opacity: 0;
        transform: translateY(-50%);
    }
    100% {
        color: $orange;
        opacity: 1;
        transform: translateY(0%);
    }
}

//ASIDE
.aside {
    background-color: rgba($black, 1);
    box-shadow: 0 0 30px 0 rgba($black, 0.7);
    height: 100vh;
    left: -230px;
    min-width: 200px;
    position: fixed;
    transition: transform .3s ease;
    transform: translateX(0px);
    z-index: 900;

    &-container {
        height: calc(100vh - 66px);
        margin-top: 66px;
        overflow: hidden;
        position: absolute;
        text-align: center;
        top: 0;
        width: 100%;
    }

    &-menu {
        margin-bottom: 20px;
        padding-left: 0;
        list-style-type: none;
        text-align: center;

        > li {
            margin-bottom: 0px;
            position: relative;

            > a {
                color: $white;
                display: block;
                font-size: 16px;
                font-weight: 700;
                padding: 10px 0;
                transition: all .3s ease;

                &:hover {
                    color: $orange;
                }
            }

            > ul {
                display: block;
                padding-left: 0;
                list-style-type: none;

                li {
                    padding-left: 0;

                    a {
                        background-color: rgba(0, 0, 0, 0.02);
                        color: $white;
                        display: block;
                        font-weight: 400;
                        font-size: 16px;
                        padding: 5px 0;
                        white-space: normal;

                        &:hover {
                            color: $orange;
                        }
                    }

                    &:before {
                        display: none;
                    }

                    &.active {
                        > a {
                            color: $orange;
                        }
                    }

                    &:first-child a {
                        border-top: 1px solid rgba(0, 0, 0, 0.3);
                    }

                    &:last-child a {
                        border-bottom: 0;
                    }
                }
            }

            &.active {
                > a {
                    color: $orange;
                }
            }
        }
    }

    &-cart {
        color: $white;
        display: block;
        margin-bottom: 30px;
        position: relative;
        transition: none;
        vertical-align: middle;
        width: 100%;

        &:hover {
            transition: none;

            .aside-cart-icon {
                color: $orange;
                transition: all .3s ease;
            }

            .aside-cart-counter {
                background-color: $orange;
                transition: all .3s ease;
            }
        }

        &:after {
            content: '';
            position: absolute;
            background-color: rgba(60, 59, 58, 0.6);
            right: -0.5px;
            height: 40px;
            top: 50%;
            transform: translateY(-50%);
            width: 1px;
        }

        &-icon {
            font-size: 28px;
            margin: 0 5px;
            transition: all .3s ease;
        }

        &-counter {
            background-color: $white;
            border-radius: 50%;
            color: $black;
            display: inline-block;
            font-size: 18.5px;
            height: 30px;
            margin: 0 5px;
            padding-top: 2px;
            text-align: center;
            transition: all .3s ease;
            width: 30px;
            vertical-align: top;
        }
    }

    &-languages {
        display: block;
        margin-bottom: 20px;
        padding-left: 0;
        position: relative;
        vertical-align: bottom;
        width: 100%;

        &-link {
            border: 1px solid $white;
            border-radius: 20px;
            color: $white;
            display: inline-block;
            font-size: 13px;
            height: 40px;
            -webkit-mask-image: -webkit-radial-gradient(white, black);
            overflow: hidden;
            padding: 10px 18.25px;
            position: relative;
            text-align: center;
            transition: background-color .3s ease, border-color .3s ease, color .3s ease;
            width: 55px;
            z-index: 0;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                right: -50px;
                bottom: 0;
                left: 0;
                border-right: 20px solid transparent;
                border-bottom: 40px solid $orange;
                transform: translateX(-100%);
                transition: .5s ease-in-out;
                z-index: -1;
            }

            &:hover {
                //background-color: $orange;
                border-color: transparent;
                color: $white;
                transition: background-color .3s ease, border-color .3s ease, color .3s ease;

                &:after {
                    transform: translateX(0);
                }
            }
        }
    }
}


//MAIN

main {
    float: left;
    width: 100%;
}

section {
    background-color: $white;
    float: left;
    position: relative;
    width: 100%;
}

//VIDEO

.video-selfhosted {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    z-index: -1;
    background-size: cover;
    background-position: center center;
    text-align: left;

    video {
        position: absolute;
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: auto;
        z-index: -1;
    }
}

//FOOTER

.footer {
    background-color: $blue;
    color: $white;
    float: left;
    font-size: 16px;
    font-weight: 400;
    line-height: 30px;
    padding: 30px 0px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 2;

    &.fixed {
        position: fixed;
        bottom: 0;
    }

    &-button {
        background-color: $orange;
        border-radius: 50%;
        display: inline-block;
        font-size: 8px;
        height: 36px;
        margin-left: -18px;
        padding: 4px 0;
        position: absolute;
        transition: background-color .3s ease, color .3s ease;
        top: -18px;
        width: 36px;

        &:hover {
            background-color: darken($orange, 10%);
            color: darken($white, 10%) !important;
            transition: background-color .3s ease, color .3s ease;
        }

        &.fixed {
            bottom: 30px;
            color: $white;
            position: fixed;
            margin: 0;
            padding: 13px 11px;
            right: 30px;
            top: auto;
            z-index: 10;
        }
    }

    a {
        color: $white;

        &:hover {
            color: $orange;
        }
    }

    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
}

//OTHER - OVERWRITE

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
    background-color: $blue;
}

// CSS specific to iOS devices
@supports (-webkit-overflow-scrolling: touch) {
    body {
        cursor: pointer;
    }
}

//ANIMATION

@keyframes hover-link {
    0% {
        transform: translateY(0%);
    }
    49% {
        opacity: 0;
        transform: translateY(50%);
    }
    50% {
        opacity: 0;
        transform: translateY(-50%);
    }
    100% {
        color: $orange;
        opacity: 1;
        transform: translateY(0%);
    }
}