@charset "UTF-8";
/*
 *  ======================================= 
 *  PROJEKT TWORZONY ZA POMOCA
 *  PREPROCESORA SCSS
 *	JESLI CHCESZ EDYTOWAC COKOLWIEK EDYTUJ
 *  PLIKI SASS  SCSS
 *  =======================================  
 */
@import url("https://fonts.googleapis.com/css?family=Asap:400,500,700&subset=latin-ext");
@font-face {
  font-family: 'unit80';
  src: url("../fonts/unit80.eot?brtgvi");
  src: url("../fonts/unit80.eot?brtgvi#iefix") format("embedded-opentype"), url("../fonts/unit80.ttf?brtgvi") format("truetype"), url("../fonts/unit80.woff?brtgvi") format("woff"), url("../fonts/unit80.svg?brtgvi#unit80") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'unit80' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-chevron_lewo:before {
  content: "\e900"; }

.icon-chevron_prawo:before {
  content: "\e901"; }

.icon-chevron_gora:before {
  content: "\e915"; }

.icon-strzalka_lewo:before {
  content: "\e902"; }

.icon-strzalka_prawo:before {
  content: "\e903"; }

.icon-strzalka_dol:before {
  content: "\e904"; }

.icon-strzalka_gora:before {
  content: "\e905"; }

.icon-szukaj:before {
  content: "\e906"; }

.icon-play:before {
  content: "\e907"; }

.icon-czas:before {
  content: "\e908"; }

.icon-email:before {
  content: "\e909"; }

.icon-fb:before {
  content: "\e90a"; }

.icon-instagram:before {
  content: "\e90b"; }

.icon-kolo:before {
  content: "\e90c"; }

.icon-koszyk:before {
  content: "\e90d"; }

.icon-koszyk_2:before {
  content: "\e90e"; }

.icon-logo_unit_80:before {
  content: "\e90f"; }

.icon-rozdzielczosc:before {
  content: "\e910"; }

.icon-telefon:before {
  content: "\e911"; }

.icon-twitter:before {
  content: "\e912"; }

.icon-vimeo:before {
  content: "\e913"; }

.icon-youtube:before {
  content: "\e914"; }

/*
 *  ======================================= 
 *  COLORS | _colors
 *  =======================================  
 */
.color-black {
  color: #000000; }

.color-white {
  color: white; }

.color-orange {
  color: #eb6e24; }

.color-blue {
  color: #252e48; }

.color-blue2 {
  color: #567793; }

.color-blue3 {
  color: #091a54; }

.color-grey {
  color: #c2c2c2; }

.color-grey2 {
  color: #8f8f8f; }

/*
 *  ======================================= 
 *  GLOBAL | _global
 *  =======================================  
 */
html, body {
  color: #000000;
  font-family: 'Asap', sans-serif;
  font-size: 16px;
  font-weight: 400;
  min-width: 320px;
  height: 100vh; }

a, .btn, button, input[type="button"], input[type="submit"], textarea:focus, input:focus {
  outline: none !important;
  box-shadow: unset !important; }

a {
  color: #999;
  transition: color .3s ease; }
  a:hover, a:focus, a:active {
    color: #666;
    text-decoration: none;
    transition: color .3s ease; }

ul:not([class]) {
  list-style-type: none;
  padding-left: 0; }
  ul:not([class]) li {
    padding-left: 20px;
    position: relative; }
    ul:not([class]) li:before {
      color: #666;
      content: "•";
      position: absolute;
      left: 0;
      transform: scale(1.2); }

ol:not([class]) {
  list-style-type: none;
  padding-left: 0; }
  ol:not([class]) li {
    counter-increment: step-counter;
    padding-left: 20px;
    position: relative; }
    ol:not([class]) li:before {
      content: counter(step-counter) ".";
      position: absolute;
      left: 0; }

.cl {
  clear: both;
  width: 100%;
  height: 1px;
  line-height: 1px;
  display: block; }

.image-to-left {
  float: left;
  margin: 0 20px 15px 0; }

.image-to-right {
  float: right;
  margin: 0 0 15px 20px; }

.layout-container {
  padding-left: 0;
  padding-right: 0; }

.cke_editable {
  padding: 15px 30px; }

.cke_widget_wrapper {
  margin-left: -15px;
  margin-right: -15px; }

.cke_widget_element {
  padding-left: 15px;
  padding-right: 15px; }

table thead th, table thead td, table tbody th, table tbody td {
  padding: 5px 10px; }

.modal {
  z-index: 3000; }
  .modal-body a {
    color: #eb6e24; }
  .modal-body .default-button {
    color: white; }
  .modal-body .more-folders {
    margin-bottom: 20px; }
  .modal-body .video-frame .resolutions {
    margin-top: 0px; }
  .modal-footer button, .modal-footer a {
    vertical-align: middle; }
  .modal-footer button + a, .modal-footer a + a, .modal-footer a + button {
    margin-bottom: 0;
    margin-left: 5px; }
  .modal .bootstrap-select {
    margin-bottom: 20px; }
  .modal .bootstrap-select.open {
    position: relative;
    z-index: 1002; }
  .modal .bootstrap-select .dropdown, .modal .dropdown {
    margin-bottom: 20px; }
    .modal .bootstrap-select .dropdown.open .dropdown-toggle, .modal .dropdown.open .dropdown-toggle {
      position: relative;
      z-index: 1001; }
    .modal .bootstrap-select .dropdown-toggle, .modal .dropdown-toggle {
      border-radius: 20px;
      padding: 9px 12px;
      width: 100%;
      z-index: 1001 !important; }
      .modal .bootstrap-select .dropdown-toggle:focus, .modal .dropdown-toggle:focus {
        outline: 0 !important; }
      .modal .bootstrap-select .dropdown-toggle .filter-option, .modal .dropdown-toggle .filter-option {
        width: calc(100% - 12px) !important; }
    .modal .bootstrap-select .dropdown-menu > li > a, .modal .dropdown-menu > li > a {
      padding-left: 12px !important; }

.default-button {
  border: 0;
  border-radius: 20px;
  color: white;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  min-width: 185px;
  overflow: hidden;
  padding: 9px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: background-color .3s ease, color .3s ease;
  z-index: 0; }
  .default-button span {
    padding: 0 34px; }
  .default-button:before {
    background-color: #252e48;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2; }
  .default-button:after {
    content: '';
    position: absolute;
    top: 0;
    right: -50px;
    bottom: 0;
    left: 0;
    border-right: 20px solid transparent;
    border-bottom: 40px solid #eb6e24;
    transform: translateX(-100%);
    transition: .5s ease-in-out;
    z-index: -1; }
  .default-button:hover {
    color: white;
    transition: background-color .3s ease, color .3s ease; }
    .default-button:hover:after {
      transform: translateX(0); }
  .default-button-white {
    border: 1px solid #c2c2c2;
    box-shadow: 0px 0px 1px 0px black !important;
    color: #000000; }
    .default-button-white:before {
      background-color: white; }
    .default-button-white:after {
      border-bottom: 40px solid #c2c2c2; }
    .default-button-white:hover {
      color: #000000; }
  .default-button-lightblue:before {
    background-color: #567793; }
  .default-button-fluidwidth {
    min-width: initial;
    padding: 9px 12px;
    white-space: nowrap;
    width: auto; }
  .default-button-fullwidth {
    min-width: initial;
    padding: 9px 12px;
    white-space: nowrap;
    width: 100%;
    vertical-align: inherit; }
  .default-button-nopointer {
    cursor: default; }

.default-transparentbutton {
  border: 0;
  border-radius: 20px;
  color: #252e48;
  display: inline-block;
  font-size: 14px;
  font-weight: 400;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  min-width: 185px;
  overflow: hidden;
  padding: 9px 0;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  transition: background-color .3s ease, color .3s ease;
  z-index: 0; }
  .default-transparentbutton:hover {
    color: #eb6e24; }
  .default-transparentbutton span {
    padding: 0 34px; }

.default-circlebutton {
  border: 0;
  border-radius: 50%;
  color: white;
  display: inline-block;
  height: 34px;
  -webkit-mask-image: -webkit-radial-gradient(white, black);
  overflow: hidden;
  padding: 6px 0;
  position: relative;
  text-align: center;
  transition: background-color .3s ease, color .3s ease;
  width: 34px;
  z-index: 0; }
  .default-circlebutton:before {
    background-color: #ccc;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -2; }
  .default-circlebutton:after {
    content: '';
    position: absolute;
    top: 0;
    right: -50px;
    bottom: 0;
    left: 0;
    border-right: 20px solid transparent;
    border-bottom: 40px solid #eb6e24;
    transform: translateX(-100%);
    transition: .5s ease-in-out;
    z-index: -1; }
  .default-circlebutton:hover {
    color: white;
    transition: background-color .3s ease, color .3s ease; }
    .default-circlebutton:hover:after {
      transform: translateX(0); }
  .default-circlebutton-red:before {
    background-color: red; }
  .default-circlebutton-blue:before {
    background-color: #252e48; }

.global-flexcontent {
  display: flex;
  flex-wrap: wrap; }
  .global-flexcontent:before, .global-flexcontent:after {
    display: none; }

.global-idscroll {
  height: 100px;
  position: absolute;
  pointer-events: none;
  top: -100px;
  width: 100%; }

.global-float--100 {
  float: left;
  width: 100%; }

.global-clear {
  clear: both; }

.global-title {
  display: inline-block;
  font-size: 50px;
  line-height: 1;
  padding: 45px 20px;
  position: relative;
  text-align: center;
  text-transform: uppercase; }
  .global-title:before, .global-title:after {
    background-color: white;
    content: '';
    height: 1px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 139px; }
  .global-title:before {
    right: 100%; }
  .global-title:after {
    left: 100%; }

.global-toggle-fixed {
  border-radius: 50%;
  bottom: 30px;
  display: none;
  font-size: 0;
  margin-bottom: 0;
  min-width: initial;
  padding: 13px 0;
  position: fixed;
  right: 30px;
  text-align: center;
  transition: opacity .3s ease;
  width: 50px;
  z-index: 10; }
  .global-toggle-fixed i {
    font-size: 24px;
    margin-left: 0; }
  .global-toggle-fixed:after {
    border-bottom: 50px solid #eb6e24; }

.hamburger {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  padding: 15px 15px;
  display: none;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 0.7;
    transition-property: opacity; }
  .hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative; }
  .hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px; }
  .hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
    width: 40px;
    height: 4px;
    background-color: white;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease; }
  .hamburger-inner:before, .hamburger-inner:after {
    content: "";
    display: block; }
  .hamburger-inner:before {
    top: -10px; }
  .hamburger-inner:after {
    bottom: -10px; }

.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse .hamburger-inner::after {
  top: -20px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }

.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }

.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

.header {
  background-color: rgba(0, 0, 0, 0.3);
  height: 135px;
  position: fixed;
  transition: background-color .3s ease, height .3s ease;
  width: 100vw;
  z-index: 901; }
  .header-blackbackgroundsubpage {
    background-color: black;
    height: 135px;
    position: fixed;
    transition: background-color .3s ease, height .3s ease;
    width: 100vw;
    z-index: 900; }
    .header-blackbackgroundsubpage.smaller {
      height: 100px;
      transition: background-color .3s ease, height .3s ease; }
  .header.blackbackground {
    background-color: black;
    transition: background-color .3s ease, height .3s ease;
    height: 100px; }
    .header.blackbackground .header-logo {
      height: 100px; }
    .header.blackbackground .header-logo-image {
      height: 75px;
      transition: height .3s ease; }
    .header.blackbackground .header-menu-desktop > li > a {
      height: 100px;
      padding: 37px 20px 37px; }
    .header.blackbackground .header-menu-desktop > li > ul li a {
      background-color: black; }
  .header-logo {
    display: inline-block;
    height: 135px;
    position: relative; }
    .header-logo-link {
      display: inline-block;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity .3s ease; }
      .header-logo-link.aside-active {
        opacity: 0;
        transition: opacity .3s ease; }
    .header-logo-image {
      height: 90px;
      transition: height .3s ease;
      width: auto; }
  .header-menu-desktop {
    display: inline-block;
    padding: 0;
    list-style-type: none;
    margin-bottom: 0;
    position: relative;
    text-align: right; }
    .header-menu-desktop:after {
      content: '';
      position: absolute;
      background-color: rgba(60, 59, 58, 0.6);
      right: -0.5px;
      height: 40px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px; }
    .header-menu-desktop li {
      display: inline-block;
      position: relative; }
      .header-menu-desktop li a {
        color: white;
        font-size: 18.5px;
        font-weight: 700;
        display: block;
        height: 135px;
        position: relative;
        padding: 55px 20px 55px;
        transition: height .3s ease, color .3s ease, transform .3s ease;
        /*
                    &:hover{
                        color: $orange;
                        &:before{
                            height: 5px;
                        }
                    }
                    */ }
        .header-menu-desktop li a:before {
          background-color: #eb6e24;
          bottom: 0;
          content: '';
          height: 0px;
          left: 0;
          position: absolute;
          transition: height .2s linear;
          width: 100%; }
      .header-menu-desktop li ul {
        display: block;
        padding-left: 0;
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 1; }
        .header-menu-desktop li ul li {
          overflow: hidden;
          height: 0;
          transition: height 200ms ease-in;
          padding-left: 0;
          display: block; }
          .header-menu-desktop li ul li:before {
            display: none; }
          .header-menu-desktop li ul li a {
            background-color: black;
            color: white;
            font-weight: 400;
            height: 45px;
            padding-top: 13.5px;
            padding-bottom: 13.5px;
            text-align: left;
            white-space: nowrap; }
      .header-menu-desktop li:hover > a {
        color: #eb6e24; }
        .header-menu-desktop li:hover > a:before {
          height: 5px; }
      .header-menu-desktop li:hover > ul li {
        height: 45px; }
        .header-menu-desktop li:hover > ul li a:hover, .header-menu-desktop li:hover > ul li a:focus {
          color: #eb6e24; }
        .header-menu-desktop li:hover > ul li.active > a {
          color: #eb6e24; }
      .header-menu-desktop li:last-child:after {
        display: none; }
      .header-menu-desktop li.active:before {
        content: '';
        position: absolute;
        background-color: rgba(60, 59, 58, 0.2);
        left: -0.5px;
        height: 40px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px; }
      .header-menu-desktop li.active:after {
        content: '';
        position: absolute;
        background-color: rgba(60, 59, 58, 0.2);
        right: -0.5px;
        height: 40px;
        top: 50%;
        transform: translateY(-50%);
        width: 1px; }
      .header-menu-desktop li.active > a {
        color: #eb6e24; }
        .header-menu-desktop li.active > a:before {
          height: 5px; }
  .header-cart {
    color: white;
    display: inline-block;
    padding: 0 20px;
    position: relative;
    transition: none;
    vertical-align: middle; }
    .header-cart:hover {
      transition: none; }
      .header-cart:hover .header-cart-icon {
        color: #eb6e24;
        transition: all .3s ease; }
      .header-cart:hover .header-cart-counter {
        background-color: #eb6e24;
        transition: all .3s ease; }
    .header-cart:after {
      content: '';
      position: absolute;
      background-color: rgba(60, 59, 58, 0.6);
      right: -0.5px;
      height: 40px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px; }
    .header-cart-icon {
      font-size: 28px;
      margin: 0 5px;
      transition: all .3s ease; }
    .header-cart-counter {
      background-color: white;
      border-radius: 50%;
      color: #000000;
      display: inline-block;
      font-size: 18.5px;
      height: 30px;
      margin: 0 5px;
      padding-top: 2px;
      text-align: center;
      transition: all .3s ease;
      width: 30px;
      vertical-align: top; }
  .header-languages {
    display: inline-block;
    position: relative;
    vertical-align: middle; }
    .header-languages-link {
      border: 1px solid white;
      border-radius: 20px;
      color: white;
      display: block;
      font-size: 13px;
      height: 40px;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      margin-left: 20px;
      overflow: hidden;
      padding: 10px 18.25px;
      position: relative;
      text-align: center;
      transition: background-color .3s ease, border-color .3s ease, color .3s ease;
      width: 55px;
      z-index: 0; }
      .header-languages-link:after {
        content: '';
        position: absolute;
        top: 0;
        right: -50px;
        bottom: 0;
        left: 0;
        border-right: 20px solid transparent;
        border-bottom: 40px solid #eb6e24;
        transform: translateX(-100%);
        transition: .5s ease-in-out;
        z-index: -1; }
      .header-languages-link:hover {
        border-color: transparent;
        color: white;
        transition: background-color .3s ease, border-color .3s ease, color .3s ease; }
        .header-languages-link:hover:after {
          transform: translateX(0); }

@keyframes hover-link {
  0% {
    transform: translateY(0%); }
  49% {
    opacity: 0;
    transform: translateY(50%); }
  50% {
    opacity: 0;
    transform: translateY(-50%); }
  100% {
    color: #eb6e24;
    opacity: 1;
    transform: translateY(0%); } }

.aside {
  background-color: black;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.7);
  height: 100vh;
  left: -230px;
  min-width: 200px;
  position: fixed;
  transition: transform .3s ease;
  transform: translateX(0px);
  z-index: 900; }
  .aside-container {
    height: calc(100vh - 66px);
    margin-top: 66px;
    overflow: hidden;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%; }
  .aside-menu {
    margin-bottom: 20px;
    padding-left: 0;
    list-style-type: none;
    text-align: center; }
    .aside-menu > li {
      margin-bottom: 0px;
      position: relative; }
      .aside-menu > li > a {
        color: white;
        display: block;
        font-size: 16px;
        font-weight: 700;
        padding: 10px 0;
        transition: all .3s ease; }
        .aside-menu > li > a:hover {
          color: #eb6e24; }
      .aside-menu > li > ul {
        display: block;
        padding-left: 0;
        list-style-type: none; }
        .aside-menu > li > ul li {
          padding-left: 0; }
          .aside-menu > li > ul li a {
            background-color: rgba(0, 0, 0, 0.02);
            color: white;
            display: block;
            font-weight: 400;
            font-size: 16px;
            padding: 5px 0;
            white-space: normal; }
            .aside-menu > li > ul li a:hover {
              color: #eb6e24; }
          .aside-menu > li > ul li:before {
            display: none; }
          .aside-menu > li > ul li.active > a {
            color: #eb6e24; }
          .aside-menu > li > ul li:first-child a {
            border-top: 1px solid rgba(0, 0, 0, 0.3); }
          .aside-menu > li > ul li:last-child a {
            border-bottom: 0; }
      .aside-menu > li.active > a {
        color: #eb6e24; }
  .aside-cart {
    color: white;
    display: block;
    margin-bottom: 30px;
    position: relative;
    transition: none;
    vertical-align: middle;
    width: 100%; }
    .aside-cart:hover {
      transition: none; }
      .aside-cart:hover .aside-cart-icon {
        color: #eb6e24;
        transition: all .3s ease; }
      .aside-cart:hover .aside-cart-counter {
        background-color: #eb6e24;
        transition: all .3s ease; }
    .aside-cart:after {
      content: '';
      position: absolute;
      background-color: rgba(60, 59, 58, 0.6);
      right: -0.5px;
      height: 40px;
      top: 50%;
      transform: translateY(-50%);
      width: 1px; }
    .aside-cart-icon {
      font-size: 28px;
      margin: 0 5px;
      transition: all .3s ease; }
    .aside-cart-counter {
      background-color: white;
      border-radius: 50%;
      color: #000000;
      display: inline-block;
      font-size: 18.5px;
      height: 30px;
      margin: 0 5px;
      padding-top: 2px;
      text-align: center;
      transition: all .3s ease;
      width: 30px;
      vertical-align: top; }
  .aside-languages {
    display: block;
    margin-bottom: 20px;
    padding-left: 0;
    position: relative;
    vertical-align: bottom;
    width: 100%; }
    .aside-languages-link {
      border: 1px solid white;
      border-radius: 20px;
      color: white;
      display: inline-block;
      font-size: 13px;
      height: 40px;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      overflow: hidden;
      padding: 10px 18.25px;
      position: relative;
      text-align: center;
      transition: background-color .3s ease, border-color .3s ease, color .3s ease;
      width: 55px;
      z-index: 0; }
      .aside-languages-link:after {
        content: '';
        position: absolute;
        top: 0;
        right: -50px;
        bottom: 0;
        left: 0;
        border-right: 20px solid transparent;
        border-bottom: 40px solid #eb6e24;
        transform: translateX(-100%);
        transition: .5s ease-in-out;
        z-index: -1; }
      .aside-languages-link:hover {
        border-color: transparent;
        color: white;
        transition: background-color .3s ease, border-color .3s ease, color .3s ease; }
        .aside-languages-link:hover:after {
          transform: translateX(0); }

main {
  float: left;
  width: 100%; }

section {
  background-color: white;
  float: left;
  position: relative;
  width: 100%; }

.video-selfhosted {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
  background-size: cover;
  background-position: center center;
  text-align: left; }
  .video-selfhosted video {
    position: absolute;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1; }

.footer {
  background-color: #252e48;
  color: white;
  float: left;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  padding: 30px 0px;
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2; }
  .footer.fixed {
    position: fixed;
    bottom: 0; }
  .footer-button {
    background-color: #eb6e24;
    border-radius: 50%;
    display: inline-block;
    font-size: 8px;
    height: 36px;
    margin-left: -18px;
    padding: 4px 0;
    position: absolute;
    transition: background-color .3s ease, color .3s ease;
    top: -18px;
    width: 36px; }
    .footer-button:hover {
      background-color: #ca5712;
      color: #e6e6e6 !important;
      transition: background-color .3s ease, color .3s ease; }
    .footer-button.fixed {
      bottom: 30px;
      color: white;
      position: fixed;
      margin: 0;
      padding: 13px 11px;
      right: 30px;
      top: auto;
      z-index: 10; }
  .footer a {
    color: white; }
    .footer a:hover {
      color: #eb6e24; }
  .footer p:last-child {
    margin-bottom: 0; }

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
  background-color: #252e48; }

@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer; } }

@keyframes hover-link {
  0% {
    transform: translateY(0%); }
  49% {
    opacity: 0;
    transform: translateY(50%); }
  50% {
    opacity: 0;
    transform: translateY(-50%); }
  100% {
    color: #eb6e24;
    opacity: 1;
    transform: translateY(0%); } }

/*
 *  ======================================= 
 *  CART | _cart
 *  =======================================  
 */
.cart {
  background-color: white;
  color: #252e48; }
  .cart-header-title {
    color: #eb6e24;
    font-size: 40px;
    max-width: 100% !important;
    padding: 50px 0 27.5px;
    text-align: left; }
    .cart-header-title:before, .cart-header-title:after {
      display: none; }
  .cart-header-nav {
    padding: 45px 0 22.5px;
    text-align: right; }
    .cart-header-nav .folder {
      display: inline-block;
      height: 25px;
      margin: 12.5px 0;
      vertical-align: middle; }
    .cart-header-nav .action {
      color: #252e48;
      display: inline-block;
      height: 25px;
      margin: 12.5px 0;
      vertical-align: middle; }
      .cart-header-nav .action i {
        vertical-align: text-bottom; }
      .cart-header-nav .action:hover {
        color: #eb6e24; }
      .cart-header-nav .action:first-child {
        border-right: 1px solid #c2c2c2;
        padding-right: 12.5px; }
      .cart-header-nav .action:last-child {
        padding-left: 12.5px; }
  .cart-options {
    margin-bottom: 30px; }
    .cart-options .row {
      margin-left: -5px;
      margin-right: -5px; }
    .cart-options .optioncolumn {
      padding-left: 5px;
      padding-right: 5px; }
    .cart-options .dropdown {
      margin-bottom: 20px; }
      .cart-options .dropdown.open .dropdown-toggle {
        position: relative;
        z-index: 1001; }
      .cart-options .dropdown-toggle {
        border-radius: 20px;
        padding: 9px 12px;
        width: 100%; }
      .cart-options .dropdown-menu {
        padding-top: 20px;
        top: calc(100% - 20px);
        width: 100%; }
        .cart-options .dropdown-menu > li > a {
          padding: 3px 13px; }
    .cart-options .title {
      display: inline-block;
      text-align: left;
      width: calc(100% - 8px); }
    .cart-options .optionbutton {
      margin-bottom: 20px;
      min-width: initial;
      width: 100%; }
    .cart-options .bordercolumn {
      position: relative; }
      .cart-options .bordercolumn:before {
        background-color: #c2c2c2;
        content: '';
        height: 14px;
        position: absolute;
        left: 0;
        top: 12px;
        width: 1px; }
    .cart-options .price {
      color: #eb6e24;
      margin-bottom: 20px;
      padding: 9px 0; }
  .cart-products .row {
    margin-left: -10px;
    margin-right: -10px; }
  .cart-products .categorycolumn {
    display: -webkit-flex;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px; }
  .cart-products .categorybox {
    background-color: rgba(143, 143, 143, 0.2);
    float: left;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%; }
  .cart-products .title {
    color: #091a54;
    font-size: 24px;
    padding-top: 20px; }
  .cart-products .video-box {
    float: left;
    margin-bottom: 8px;
    margin-top: 15px;
    width: 100%; }
  .cart-products .video-image {
    display: block;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative; }
    .cart-products .video-image:hover .more {
      color: white;
      transition: color .3s ease .1s; }
    .cart-products .video-image:hover .image {
      opacity: 0.7;
      transform: scale(1.2); }
    .cart-products .video-image .more {
      color: rgba(255, 255, 255, 0);
      font-size: 20px;
      left: 50%;
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: color .3s ease;
      z-index: 1; }
    .cart-products .video-image .image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all .3s ease;
      position: absolute;
      height: 100%;
      width: 100%; }
    .cart-products .video-image .ratio {
      background-color: #000000;
      padding-bottom: 55%; }
  .cart-products .video-caption {
    float: left;
    position: relative;
    top: 2px;
    width: 100%; }
  .cart-products .video-title {
    color: #4d4d4d;
    float: left;
    font-size: 13px;
    margin-bottom: 10px; }
  .cart-products .video-details {
    float: right;
    margin-bottom: 10px; }
    .cart-products .video-details i {
      color: #eb6e24;
      float: left;
      margin-right: 5px; }
  .cart-products .video-time {
    display: inline-block;
    float: left;
    margin-right: 10px; }
    .cart-products .video-time-value {
      color: #000000;
      float: left;
      font-size: 13px; }
  .cart-products .video-resolution {
    display: inline-block;
    float: left; }
    .cart-products .video-resolution-value {
      color: #000000;
      float: left;
      font-size: 13px; }
  .cart-products .video-description {
    display: none;
    float: left;
    margin-bottom: 10px;
    width: 100%; }
  .cart-products .video-price {
    color: #000000;
    float: right;
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 10px;
    text-align: right; }
  .cart-products .video-comment {
    float: left;
    font-size: 16px;
    font-style: italic;
    text-align: justify;
    width: 100%; }
  .cart-products .video-buttons {
    float: left;
    text-align: right; }
    .cart-products .video-buttons a, .cart-products .video-buttons button {
      margin-right: 15px; }

_:-moz-tree-row(hover), .cart-products .categorybox {
  line-height: 1.2;
  padding-bottom: 3px; }

/*
 *  ======================================= 
 *  HOME PAGE | _home_page
 *  =======================================  
 */
.homepage-header {
  height: 100vh;
  position: relative;
  z-index: 0; }
  .homepage-header-background {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1; }
  .homepage-header-bottom {
    bottom: 40px;
    position: absolute;
    text-align: center;
    width: 100%; }
  .homepage-header-button {
    animation: scrolldown 2s infinite;
    background-color: #eb6e24;
    border-radius: 50%;
    color: white;
    display: inline-block;
    height: 45px;
    padding-top: 12px;
    transition: background-color .3s ease, color .3s ease;
    width: 45px; }
    .homepage-header-button:hover {
      background-color: #ca5712;
      color: #e6e6e6;
      transition: background-color .3s ease, color .3s ease; }

.homepage-aboutus {
  background-color: #252e48;
  color: white; }
  .homepage-aboutus-text {
    color: #c2c2c2;
    margin-bottom: 110px; }
    .homepage-aboutus-text ul:last-child, .homepage-aboutus-text p:last-child {
      margin-bottom: 0; }
  .homepage-aboutus-row {
    margin-left: -5px;
    margin-right: -5px; }
    .homepage-aboutus-row [class*="col-"] {
      padding-left: 5px;
      padding-right: 5px; }
  .homepage-aboutus-asset {
    margin-bottom: 100px;
    position: relative; }
    .homepage-aboutus-asset .arc {
      display: inline-block;
      position: relative;
      vertical-align: middle; }
    .homepage-aboutus-asset i {
      color: #567793;
      font-size: 170px; }
    .homepage-aboutus-asset .number {
      color: #eb6e24;
      font-size: 50px;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%); }
    .homepage-aboutus-asset .text {
      color: white;
      display: inline-block;
      font-size: 20px;
      line-height: 120%;
      margin-left: 30px;
      vertical-align: middle; }
      .homepage-aboutus-asset .text p {
        margin-bottom: 0; }

.homepage-offer {
  background-color: transparent;
  color: #252e48; }
  .homepage-offer-title:before, .homepage-offer-title:after {
    background-color: #252e48;
    height: 1.5px; }
  .homepage-offer-background, .homepage-offer-background2 {
    background-attachment: fixed;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 400px;
    width: 100%;
    z-index: -1; }
  .homepage-offer-section {
    float: left;
    padding-top: 90px;
    padding-bottom: 90px;
    width: 100%; }
    .homepage-offer-section:first-child {
      padding-top: 0;
      padding-bottom: 50px; }
    .homepage-offer-section.darkerbackground {
      background-color: #252e48; }
      .homepage-offer-section.darkerbackground .homepage-offer-box .title, .homepage-offer-section.darkerbackground .homepage-offer-box .text, .homepage-offer-section.darkerbackground .homepage-offer-box .bottombutton {
        padding-left: 0;
        padding-right: 40px; }
      .homepage-offer-section.darkerbackground .homepage-offer-box .title {
        color: white; }
      .homepage-offer-section.darkerbackground .homepage-offer-box .text {
        color: rgba(255, 255, 255, 0.5); }
  .homepage-offer-image {
    display: block;
    height: 412px;
    position: relative; }
    .homepage-offer-image.nolink .divimage {
      opacity: 1.0 !important; }
    .homepage-offer-image.nolink i {
      color: rgba(255, 255, 255, 0) !important; }
    .homepage-offer-image:hover .divimage {
      opacity: 0.7;
      transition: opacity .3s ease; }
    .homepage-offer-image:hover i {
      color: white;
      transition: color .3s ease .1s; }
    .homepage-offer-image .divimage {
      opacity: 1;
      transition: opacity .3s ease; }
    .homepage-offer-image i {
      color: rgba(255, 255, 255, 0);
      font-size: 75px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: color .3s ease;
      z-index: 1; }
  .homepage-offer-box {
    padding-bottom: 60px; }
    .homepage-offer-box .title {
      color: #252e48;
      display: inline-block;
      font-size: 40px;
      margin-top: 5px;
      margin-bottom: 35px;
      padding-left: 40px; }
    .homepage-offer-box .text {
      color: rgba(37, 46, 72, 0.9);
      font-size: 20px;
      padding-left: 40px; }
      .homepage-offer-box .text p, .homepage-offer-box .text ul {
        margin-bottom: 30px; }
    .homepage-offer-box .bottombutton {
      bottom: 0;
      padding-left: 40px;
      position: absolute; }

.homepage-stock {
  background-color: white;
  color: #252e48; }
  .homepage-stock-background {
    background-attachment: fixed;
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 400px;
    width: 100%;
    z-index: -1; }
  .homepage-stock-title:before, .homepage-stock-title:after {
    background-color: #252e48;
    height: 1.5px; }
  .homepage-stock-searchform {
    display: inline-block;
    height: 50px;
    margin-bottom: 50px;
    max-width: 100%;
    position: relative;
    width: 665px; }
    .homepage-stock-searchform i {
      color: #567793;
      font-size: 20px;
      left: 20px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .homepage-stock-searchform input {
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 20px;
      height: 50px;
      padding-left: 60px;
      padding-right: 145px;
      transition: border-color .3s ease;
      width: 100%; }
      .homepage-stock-searchform input:focus {
        border-color: rgba(37, 46, 72, 0.6);
        transition: border-color .3s ease; }
    .homepage-stock-searchform button {
      border: 0;
      border-radius: 20px;
      color: white;
      height: 50px;
      -webkit-mask-image: -webkit-radial-gradient(white, black);
      overflow: hidden;
      position: absolute;
      right: 0;
      text-transform: uppercase;
      transition: background-color .3s ease, color .3s ease;
      top: 0;
      width: 135px;
      z-index: 0; }
      .homepage-stock-searchform button:before {
        background-color: #eb6e24;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -2; }
      .homepage-stock-searchform button:after {
        content: '';
        position: absolute;
        top: 0;
        right: -75px;
        bottom: 0;
        left: 0;
        border-right: 25px solid transparent;
        border-bottom: 50px solid #252e48;
        transform: translateX(-100%);
        transition: .5s ease-in-out;
        z-index: -1; }
      .homepage-stock-searchform button:hover {
        color: white;
        transition: background-color .3s ease, color .3s ease; }
        .homepage-stock-searchform button:hover:after {
          transform: translateX(0); }
  .homepage-stock-filters {
    margin-top: 45px;
    margin-bottom: 30px; }
    .homepage-stock-filters.fixed {
      background: white;
      box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.5);
      margin: 0 auto;
      padding: 25px 0 0;
      position: fixed;
      transition: top .3s ease;
      width: 100%;
      z-index: 901; }
      .homepage-stock-filters.fixed.lowertop {
        top: 100px; }
    .homepage-stock-filters .row {
      margin-left: -10px;
      margin-right: -10px; }
    .homepage-stock-filters .filtercolumn {
      padding-left: 10px;
      padding-right: 10px; }
    .homepage-stock-filters .bootstrap-select {
      margin-bottom: 20px; }
    .homepage-stock-filters .bootstrap-select.open {
      position: relative;
      z-index: 802; }
    .homepage-stock-filters .bootstrap-select .dropdown, .homepage-stock-filters .dropdown {
      margin-bottom: 20px; }
      .homepage-stock-filters .bootstrap-select .dropdown.open .dropdown-toggle, .homepage-stock-filters .dropdown.open .dropdown-toggle {
        position: relative;
        z-index: 801; }
      .homepage-stock-filters .bootstrap-select .dropdown-toggle, .homepage-stock-filters .dropdown-toggle {
        border-radius: 20px;
        padding: 9px 12px;
        width: 100%;
        z-index: 801 !important; }
        .homepage-stock-filters .bootstrap-select .dropdown-toggle:focus, .homepage-stock-filters .dropdown-toggle:focus {
          outline: 0 !important; }
        .homepage-stock-filters .bootstrap-select .dropdown-toggle .filter-option, .homepage-stock-filters .dropdown-toggle .filter-option {
          width: calc(100% - 12px) !important; }
      .homepage-stock-filters .bootstrap-select .dropdown-header, .homepage-stock-filters .dropdown-header {
        padding: 3px 12px !important; }
      .homepage-stock-filters .bootstrap-select .dropdown-menu > li > a, .homepage-stock-filters .dropdown-menu > li > a {
        padding-left: 12px !important; }
    .homepage-stock-filters .filter-title {
      font-size: 16px;
      margin-bottom: 0;
      padding-left: 13px;
      text-transform: uppercase; }
    .homepage-stock-filters .filter-input {
      border: 1px solid #ccc;
      border-radius: 20px;
      color: #333;
      background-color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857143;
      margin-bottom: 20px;
      padding: 9px 12px;
      transition: border .3s ease;
      width: 100%; }
      .homepage-stock-filters .filter-input:focus {
        border-color: #000000; }
    .homepage-stock-filters .filter-fullwidthbutton {
      margin-bottom: 20px;
      min-width: initial;
      width: 100%; }
      .homepage-stock-filters .filter-fullwidthbutton.no-margin {
        margin-bottom: 0; }
      .homepage-stock-filters .filter-fullwidthbutton i {
        font-size: 12px;
        margin-left: 5px; }
    .homepage-stock-filters .filter-clearfilter {
      display: inline-block;
      font-size: 13px;
      margin-bottom: 4px;
      text-align: center;
      width: 100%; }
      .homepage-stock-filters .filter-clearfilter i {
        margin-left: -5px;
        vertical-align: middle; }
    .homepage-stock-filters .filter-options {
      float: left;
      width: 100%; }
    .homepage-stock-filters .filter-searchform {
      display: inline-block;
      height: 40px;
      margin-bottom: 20px;
      max-width: 100%;
      position: relative;
      width: 100%; }
      .homepage-stock-filters .filter-searchform input {
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 20px;
        font-size: 14px;
        height: 40px;
        padding-left: 15px;
        padding-right: 15px;
        transition: border-color .3s ease;
        width: 100%; }
        .homepage-stock-filters .filter-searchform input:focus {
          border-color: rgba(37, 46, 72, 0.6);
          transition: border-color .3s ease; }
      .homepage-stock-filters .filter-searchform button {
        border: 0;
        border-radius: 20px;
        color: white;
        font-size: 14px;
        height: 40px;
        overflow: hidden;
        position: absolute;
        right: 0;
        text-transform: uppercase;
        transition: background-color .3s ease, color .3s ease;
        top: 0;
        width: 40px;
        z-index: 0; }
        .homepage-stock-filters .filter-searchform button i {
          vertical-align: text-bottom; }
        .homepage-stock-filters .filter-searchform button:before {
          background-color: #eb6e24;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -2; }
        .homepage-stock-filters .filter-searchform button:after {
          content: '';
          position: absolute;
          top: 0;
          right: -75px;
          bottom: 0;
          left: 0;
          border-right: 25px solid transparent;
          border-bottom: 50px solid #252e48;
          transform: translateX(-100%);
          transition: .5s ease-in-out;
          z-index: -1; }
        .homepage-stock-filters .filter-searchform button:hover {
          color: white;
          transition: background-color .3s ease, color .3s ease; }
          .homepage-stock-filters .filter-searchform button:hover:after {
            transform: translateX(0); }
    .homepage-stock-filters .bootstrap-tagsinput {
      align-items: center;
      display: inline-flex;
      overflow: hidden;
      text-align: right;
      white-space: nowrap;
      border: 1px solid #ccc;
      border-radius: 20px;
      color: #333;
      background-color: #fff;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.42857143;
      margin-bottom: 20px;
      padding: 9px 12px;
      transition: border .3s ease;
      width: 100%; }
      .homepage-stock-filters .bootstrap-tagsinput:focus {
        border-color: #000000; }
      .homepage-stock-filters .bootstrap-tagsinput span {
        display: none !important; }
    .homepage-stock-filters .toggle-filters {
      margin-bottom: 20px; }
      .homepage-stock-filters .toggle-filters span {
        padding: 0; }
    .homepage-stock-filters .title {
      display: inline-block;
      text-align: left;
      width: calc(100% - 8px); }
  .homepage-stock-categories.extrapadding {
    padding-top: 110px; }
  .homepage-stock-categories .row {
    margin-left: -10px;
    margin-right: -10px; }
  .homepage-stock-categories .categorycolumn {
    display: -webkit-flex;
    margin-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px; }
  .homepage-stock-categories .categorybox {
    background-color: rgba(143, 143, 143, 0.2);
    float: left;
    height: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%; }
    .homepage-stock-categories .categorybox.bigger {
      padding-left: 20px;
      padding-right: 20px; }
      .homepage-stock-categories .categorybox.bigger .video-title {
        font-size: 20px; }
      .homepage-stock-categories .categorybox.bigger .video-caption {
        top: 0; }
      .homepage-stock-categories .categorybox.bigger .video-details i {
        font-size: 20px;
        margin-top: 4px;
        position: relative; }
      .homepage-stock-categories .categorybox.bigger .video-time {
        margin-right: 15px; }
      .homepage-stock-categories .categorybox.bigger .video-time-value, .homepage-stock-categories .categorybox.bigger .video-resolution-value {
        font-size: 20px; }
  .homepage-stock-categories .title {
    color: #091a54;
    display: block;
    font-size: 24px;
    padding-top: 20px;
    text-align: center;
    text-decoration: underline; }
    .homepage-stock-categories .title:hover {
      color: #eb6e24; }
  .homepage-stock-categories .video-box {
    float: left;
    margin-bottom: 8px;
    margin-top: 15px;
    width: 100%; }
    .homepage-stock-categories .video-box:not(.loaded) .video-button, .homepage-stock-categories .video-box:not(.loaded) .video-details {
      opacity: 0;
      pointer-events: none; }
  .homepage-stock-categories .video-image {
    display: block;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative; }
    .homepage-stock-categories .video-image:hover .more {
      color: white;
      transition: color .3s ease .1s; }
    .homepage-stock-categories .video-image:hover .image {
      opacity: 0.7;
      transform: scale(1.2); }
    .homepage-stock-categories .video-image .more {
      color: rgba(255, 255, 255, 0);
      font-size: 20px;
      left: 50%;
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: color .3s ease;
      z-index: 1; }
    .homepage-stock-categories .video-image .image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all .3s ease;
      position: absolute;
      height: 100%;
      width: 100%; }
      .homepage-stock-categories .video-image .image.lazyload {
        opacity: 0; }
        .homepage-stock-categories .video-image .image.lazyload.loaded {
          opacity: 1; }
      .homepage-stock-categories .video-image .image .lazyload-trigger {
        visibility: hidden; }
    .homepage-stock-categories .video-image .ratio {
      background-color: #000000;
      background-image: url(/frontend/img/loader.gif);
      background-position: center center;
      background-size: auto;
      background-repeat: no-repeat;
      padding-bottom: 55%; }
  .homepage-stock-categories .video-caption {
    float: left;
    position: relative;
    top: 2px;
    width: 100%; }
  .homepage-stock-categories .video-title {
    color: #4d4d4d;
    float: left;
    font-size: 13px; }
  .homepage-stock-categories .video-details {
    float: right;
    transition: opacity .9s ease; }
    .homepage-stock-categories .video-details i {
      color: #eb6e24;
      float: left;
      margin-right: 5px; }
  .homepage-stock-categories .video-time {
    display: inline-block;
    float: left;
    margin-right: 10px; }
    .homepage-stock-categories .video-time-value {
      color: #000000;
      float: left;
      font-size: 13px; }
  .homepage-stock-categories .video-resolution {
    display: inline-block;
    float: left; }
    .homepage-stock-categories .video-resolution-value {
      color: #000000;
      float: left;
      font-size: 13px; }
  .homepage-stock-categories .video-button {
    float: left;
    margin-top: 0;
    margin-left: 5px;
    transition: opacity .6s ease;
    width: auto; }
    .homepage-stock-categories .video-button a {
      color: #252e48;
      height: auto;
      padding: 0;
      top: -2px;
      width: auto; }
      .homepage-stock-categories .video-button a i {
        transition: .2s ease-in-out; }
      .homepage-stock-categories .video-button a:before {
        background: none;
        border: none; }
      .homepage-stock-categories .video-button a:after {
        display: none; }
      .homepage-stock-categories .video-button a:hover i {
        font-size: 18px;
        color: #eb6e24; }
      .homepage-stock-categories .video-button a:hover:after {
        background: none;
        border: none; }
  .homepage-stock-button {
    padding-top: 30px;
    padding-bottom: 50px; }

.homepage-realizations {
  background-color: #252e48;
  color: white; }
  .homepage-realizations-holder {
    position: relative; }
  .homepage-realizations-carousel .active.center .item {
    opacity: 1;
    transition: opacity .3s ease; }
  .homepage-realizations-carousel .active.center .box {
    pointer-events: all; }
  .homepage-realizations-carousel .active.center i {
    color: #eb6e24;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    transition: color .3s ease, text-shadow .3s ease; }
  .homepage-realizations-carousel .active.center .caption {
    background-color: white;
    color: #010101;
    transition: background-color .3s ease, color .3s ease; }
  .homepage-realizations-carousel .item {
    opacity: 0.5;
    transition: opacity .3s ease; }
  .homepage-realizations-carousel .box {
    display: block;
    pointer-events: none;
    position: relative; }
    .homepage-realizations-carousel .box:hover .image {
      opacity: 0.7;
      transition: opacity .3s ease; }
    .homepage-realizations-carousel .box:hover i {
      color: white;
      transition: color .3s ease; }
  .homepage-realizations-carousel i {
    color: rgba(235, 110, 36, 0);
    font-size: 85px;
    left: 50%;
    position: absolute;
    text-shadow: 0px 0px 20px transparent;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: color .3s ease .2s, text-shadow .3s ease .2s;
    z-index: 1; }
  .homepage-realizations-carousel .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    transition: opacity .3s ease;
    height: 100%;
    width: 100%; }
  .homepage-realizations-carousel .ratio {
    padding-bottom: 56.25%; }
  .homepage-realizations-carousel .caption {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    font-size: 20px;
    height: 60px;
    padding: 16px 0;
    text-align: center;
    transition: background-color .3s ease, color .3s ease; }
  .homepage-realizations-controls {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    pointer-events: none;
    width: 100%;
    z-index: 2; }
    .homepage-realizations-controls i {
      background-color: #567793;
      color: white;
      display: inline-block;
      font-size: 21px;
      padding: 24.5px 30px; }
    .homepage-realizations-controls .owl-prev, .homepage-realizations-controls .owl-next {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 0;
      pointer-events: all;
      position: absolute;
      top: calc(50% - 30px);
      transform: translateY(-50%); }
    .homepage-realizations-controls .owl-prev {
      left: 0px; }
      .homepage-realizations-controls .owl-prev i {
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
        padding-right: 50px;
        transition: background-color .3s ease, color .3s ease, padding .3s ease; }
        .homepage-realizations-controls .owl-prev i:hover {
          background-color: #435d73;
          color: #eb6e24;
          padding-left: 45px;
          transition: background-color .3s ease, color .3s ease, padding .3s ease; }
    .homepage-realizations-controls .owl-next {
      right: 0px; }
      .homepage-realizations-controls .owl-next i {
        border-top-left-radius: 35px;
        border-bottom-left-radius: 35px;
        padding-left: 50px;
        transition: background-color .3s ease, color .3s ease, padding .3s ease; }
        .homepage-realizations-controls .owl-next i:hover {
          background-color: #435d73;
          color: #eb6e24;
          padding-right: 45px;
          transition: background-color .3s ease, color .3s ease, padding .3s ease; }
  .homepage-realizations-dots {
    margin: 30px 0;
    pointer-events: all;
    text-align: center; }
    .homepage-realizations-dots-holder {
      left: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: 2; }
    .homepage-realizations-dots .owl-dot {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      display: inline-block;
      padding: 0;
      zoom: 1; }
      .homepage-realizations-dots .owl-dot span {
        background-color: rgba(235, 110, 36, 0);
        border: 3px solid white;
        width: 15px;
        height: 15px;
        margin: 20px 5px 20px 5px;
        display: block;
        -webkit-backface-visibility: visible;
        transition: all .3s ease;
        border-radius: 50%; }
      .homepage-realizations-dots .owl-dot.active span, .homepage-realizations-dots .owl-dot:hover span {
        background-color: #eb6e24;
        border: 3px solid #eb6e24;
        border-radius: 50%;
        transition: all .3s ease; }
  .homepage-realizations-logos {
    background-color: white;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.2);
    color: #252e48;
    position: relative;
    z-index: 1; }
    .homepage-realizations-logos-title:before, .homepage-realizations-logos-title:after {
      background-color: #252e48;
      height: 1.5px; }
    .homepage-realizations-logos-holder {
      margin-bottom: 60px;
      position: relative; }
    .homepage-realizations-logos-carousel .box {
      position: relative; }
    .homepage-realizations-logos-carousel .owl-stage {
      transition-timing-function: linear !important; }
    .homepage-realizations-logos-carousel .owl-item {
      height: 80px; }
    .homepage-realizations-logos-carousel .item {
      height: 100%;
      position: relative; }
    .homepage-realizations-logos-carousel img {
      display: inline-block !important;
      image-rendering: pixelated;
      left: 50%;
      max-width: 100%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: auto !important; }
    .homepage-realizations-logos-carousel .image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      height: 100%;
      width: 100%; }
    .homepage-realizations-logos-carousel .ratio {
      padding-bottom: 35%; }
    .homepage-realizations-logos-controls {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      pointer-events: none;
      width: 100%;
      z-index: 2; }
      .homepage-realizations-logos-controls.disabled {
        display: none; }
      .homepage-realizations-logos-controls i {
        color: #eb6e24;
        display: inline-block;
        font-size: 41px;
        padding: 24.5px 30px;
        transition: color .3s ease; }
        .homepage-realizations-logos-controls i:hover {
          color: #252e48;
          transition: color .3s ease; }
      .homepage-realizations-logos-controls .owl-prev, .homepage-realizations-logos-controls .owl-next {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        padding: 0;
        pointer-events: all;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .homepage-realizations-logos-controls .owl-prev {
        left: 0px; }
      .homepage-realizations-logos-controls .owl-next {
        right: 0px; }
  .homepage-realizations-map {
    background-color: white;
    height: 540px;
    position: relative; }
    .homepage-realizations-map .image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    .homepage-realizations-map .caption {
      bottom: 35px;
      color: #252e48;
      font-size: 30px;
      padding: 0 15px;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      width: 100%;
      z-index: 1; }

.homepage-contact {
  background-color: #567793;
  color: white; }
  .homepage-contact-box {
    height: 180px;
    position: relative; }
    .homepage-contact-box:before, .homepage-contact-box:after {
      background-color: rgba(255, 255, 255, 0.3);
      content: '';
      height: 100%;
      position: absolute;
      width: 1px; }
    .homepage-contact-box:before {
      left: 0; }
    .homepage-contact-box:after {
      right: 0; }
    .homepage-contact-box:first-child:before {
      display: none; }
    .homepage-contact-box:last-child:after {
      display: none; }
    .homepage-contact-box .centering {
      left: 15px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: calc(100% - 30px); }
    .homepage-contact-box .phonelinks {
      display: inline-block;
      margin-left: 32px;
      vertical-align: middle; }
    .homepage-contact-box .icon-telefon {
      color: rgba(255, 255, 255, 0.5);
      font-size: 44px;
      vertical-align: middle; }
    .homepage-contact-box .phonelink {
      color: white;
      display: block;
      font-size: 32px;
      vertical-align: middle; }
      .homepage-contact-box .phonelink:hover {
        color: rgba(255, 255, 255, 0.5); }
    .homepage-contact-box .icon-email {
      color: rgba(255, 255, 255, 0.5);
      font-size: 44px;
      vertical-align: middle; }
    .homepage-contact-box .emaillink {
      color: white;
      font-size: 24px;
      margin-left: 30px;
      vertical-align: middle; }
      .homepage-contact-box .emaillink:hover {
        color: rgba(255, 255, 255, 0.5); }
    .homepage-contact-box .medialink {
      display: inline-block;
      margin-left: 60px; }
      .homepage-contact-box .medialink i {
        color: rgba(255, 255, 255, 0.5);
        font-size: 44px;
        transition: color .3s ease; }
      .homepage-contact-box .medialink:hover i {
        color: white;
        transition: color .3s ease; }

@keyframes scrolldown {
  0% {
    transform: translate(0%, 0px);
    -webkit-transform: translate(0%, 0px); }
  50% {
    transform: translate(0%, -20px);
    -webkit-transform: translate(0%, -20px); }
  100% {
    transform: translate(0%, 0px);
    -webkit-transform: translate(0%, 0px); } }

_:-moz-tree-row(hover), .homepage-stock-categories .categorybox {
  line-height: 1.2;
  padding-bottom: 3px; }

_:-moz-tree-row(hover), .homepage-stock-categories .categorybox.bigger {
  line-height: 1.42857143;
  padding-bottom: 0; }

/*
 *  ======================================= 
 *  ORDER | _order
 *  =======================================  
 */
.order-header-title {
  color: #eb6e24;
  max-width: 100% !important;
  padding: 45px 0 45px;
  text-align: left; }
  .order-header-title:before, .order-header-title:after {
    display: none; }

.order-main-title {
  font-size: 20px;
  margin-bottom: 20px; }

.order-main-form {
  margin-bottom: 40px; }
  .order-main-form .is-sending {
    position: relative;
    pointer-events: none; }
    .order-main-form .is-sending:before {
      background: rgba(255, 255, 255, 0.5);
      content: '';
      height: 100%;
      position: absolute;
      top: 0;
      transition: background .3s ease;
      width: 100%;
      z-index: 850; }
    .order-main-form .is-sending:after {
      background: #252e48;
      border-radius: 20px;
      color: white;
      content: 'Trwa wysyłanie formularza. Proszę czekać...';
      height: 45px;
      padding: 10.5px 20px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 851; }
    .order-main-form .is-sending-en:after {
      content: 'The order form is sending, please wait...'; }
  .order-main-form input[type='text'], .order-main-form textarea {
    border: 1px solid #ccc;
    border-radius: 20px;
    color: #333;
    background-color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    margin-bottom: 20px;
    padding: 9px 12px;
    resize: vertical;
    transition: border-color .3s ease;
    width: 100%; }
    .order-main-form input[type='text'].border-orange, .order-main-form textarea.border-orange {
      border-color: #eb6e24; }
    .order-main-form input[type='text']:focus, .order-main-form textarea:focus {
      border-color: rgba(37, 46, 72, 0.6);
      transition: border-color .3s ease; }
  .order-main-form label {
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 20px;
    user-select: none;
    width: 100%; }
    .order-main-form label.lower-margin {
      margin-bottom: 10px; }
  .order-main-form input[type='checkbox'] {
    display: inline-block;
    vertical-align: top; }
  .order-main-form .checkbox-text {
    display: inline-block;
    font-size: 14px;
    font-style: italic;
    transition: color .3s ease;
    width: calc(100% - 30px); }
    .order-main-form .checkbox-text.text-orange {
      color: #eb6e24; }
    .order-main-form .checkbox-text a {
      color: #eb6e24;
      text-decoration: underline; }
  .order-main-form .bootstrap-select {
    margin-bottom: 20px;
    width: 100% !important; }
  .order-main-form .bootstrap-select.open {
    position: relative;
    z-index: 802; }
  .order-main-form .bootstrap-select .dropdown, .order-main-form .dropdown {
    margin-bottom: 20px; }
    .order-main-form .bootstrap-select .dropdown.open .dropdown-toggle, .order-main-form .dropdown.open .dropdown-toggle {
      position: relative;
      z-index: 801; }
    .order-main-form .bootstrap-select .dropdown-toggle, .order-main-form .dropdown-toggle {
      border-radius: 20px;
      padding: 9px 12px;
      width: 100%;
      z-index: 801 !important; }
      .order-main-form .bootstrap-select .dropdown-toggle:focus, .order-main-form .dropdown-toggle:focus {
        outline: 0 !important; }
      .order-main-form .bootstrap-select .dropdown-toggle .filter-option, .order-main-form .dropdown-toggle .filter-option {
        width: calc(100% - 12px) !important; }
    .order-main-form .bootstrap-select .dropdown-header, .order-main-form .dropdown-header {
      padding: 3px 12px !important; }
    .order-main-form .bootstrap-select .dropdown-menu > li > a, .order-main-form .dropdown-menu > li > a {
      padding-left: 12px !important; }

.order-main-agreements {
  margin-top: 10px; }

.order-main-list {
  float: left;
  margin-bottom: 20px;
  width: 100%; }
  .order-main-list .video-box {
    border-bottom: 1px solid #c2c2c2;
    float: left;
    margin-bottom: 20px;
    padding-bottom: 20px;
    width: 100%; }
    .order-main-list .video-box:last-child {
      border-bottom: 0; }
  .order-main-list .video-image {
    border: 1px solid #c2c2c2;
    display: block;
    float: left;
    overflow: hidden;
    position: relative;
    width: 25%; }
    .order-main-list .video-image:hover .image {
      opacity: 0.7;
      transform: scale(1.2); }
    .order-main-list .video-image .image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all .3s ease;
      position: absolute;
      height: 100%;
      width: 100%; }
    .order-main-list .video-image .ratio {
      background-color: #000000;
      padding-bottom: 55%; }
  .order-main-list .video-caption {
    display: block;
    float: left;
    padding-left: 15px;
    width: 75%; }
  .order-main-list .video-title {
    color: #eb6e24;
    float: left;
    font-size: 16px;
    margin-bottom: 10px; }
    .order-main-list .video-title:hover {
      color: #000000; }
  .order-main-list .video-details {
    float: left;
    margin-bottom: 10px;
    width: 100%; }
    .order-main-list .video-details i {
      color: #eb6e24;
      margin-right: 2px; }
  .order-main-list .video-time {
    display: inline-block; }
    .order-main-list .video-time-value {
      color: #000000;
      font-size: 16px;
      vertical-align: text-bottom; }
  .order-main-list .video-resolution {
    display: inline-block;
    margin-left: 6px; }
    .order-main-list .video-resolution-value {
      color: #000000;
      font-size: 16px;
      vertical-align: text-bottom; }
  .order-main-list .video-description {
    display: none;
    float: left;
    margin-bottom: 10px;
    width: 100%; }
  .order-main-list .video-price {
    color: #000000;
    float: right;
    font-size: 16px;
    margin-bottom: 10px;
    text-align: right; }
  .order-main-list .video-comment {
    float: left;
    font-size: 16px;
    font-style: italic;
    text-align: justify;
    width: 100%; }
  .order-main-list .video-licence {
    float: left;
    font-size: 16px;
    text-align: left;
    width: 100%; }
    .order-main-list .video-licence .title {
      margin-bottom: 5px; }
    .order-main-list .video-licence input[type=checkbox], .order-main-list .video-licence input[type=radio] {
      left: 0;
      position: absolute; }
    .order-main-list .video-licence label {
      cursor: pointer;
      font-weight: 400;
      padding-left: 20px;
      position: relative;
      user-select: none;
      width: 100%; }

/*
 *  ======================================= 
 *  SUBPAGE | _subpage
 *  =======================================  
 */
.subpage {
  background-color: #567793;
  color: white;
  min-height: calc(100vh - 120px);
  padding-top: 185px; }
  .subpage-spacer {
    background-color: #000000;
    height: 135px; }
    .subpage-spacer.smaller {
      height: 100px;
      transition: background-color .3s ease, height .3s ease; }
  .subpage-nopadding {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .subpage-margin {
    margin-top: 80px;
    margin-bottom: 80px; }
  .subpage-darkerbackground {
    background-color: #252e48;
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: -1; }
  .subpage-bottomspacer {
    height: 45px; }
  .subpage-title:before, .subpage-title:after {
    background-color: #252e48;
    height: 1.5px; }
  .subpage-carousel-holder {
    position: relative; }
  .subpage-carousel .active.center .item {
    opacity: 1;
    transition: opacity .3s ease; }
  .subpage-carousel .active.center .box {
    pointer-events: all; }
  .subpage-carousel .active.center i {
    color: #eb6e24;
    text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.4);
    transition: color .3s ease, text-shadow .3s ease; }
  .subpage-carousel .active.center .caption {
    background-color: white;
    color: #010101;
    transition: background-color .3s ease, color .3s ease; }
  .subpage-carousel i {
    color: rgba(255, 255, 255, 0);
    font-size: 85px;
    left: 50%;
    position: absolute;
    text-shadow: 0px 0px 20px transparent;
    top: 50%;
    transform: translate(-50%, -50%);
    transition: color .3s ease .2s, text-shadow .3s ease .2s;
    z-index: 1; }
  .subpage-carousel .gallery-image {
    transition: opacity .3s ease; }
  .subpage-carousel .magnific-popup:hover .gallery-image {
    opacity: 0.8;
    transition: opacity .3s ease; }
  .subpage-carousel .magnific-popup:hover i {
    color: white;
    text-shadow: 0px 0px 20px black;
    transition: color .3s ease .2s, text-shadow .3s ease .2s; }
  .subpage-carousel .image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    transition: opacity .3s ease;
    height: 100%;
    width: 100%; }
  .subpage-carousel .ratio {
    padding-bottom: 76%; }
  .subpage-carousel .caption {
    background-color: rgba(255, 255, 255, 0);
    color: white;
    font-size: 20px;
    height: 60px;
    padding: 16px 0;
    text-align: center;
    transition: background-color .3s ease, color .3s ease; }
  .subpage-carousel-controls {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    pointer-events: none;
    width: 100%;
    z-index: 2; }
    .subpage-carousel-controls i {
      background-color: #567793;
      color: white;
      display: inline-block;
      font-size: 21px;
      padding: 24.5px 30px; }
    .subpage-carousel-controls .owl-prev, .subpage-carousel-controls .owl-next {
      background-color: transparent;
      border: 0;
      cursor: pointer;
      padding: 0;
      pointer-events: all;
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .subpage-carousel-controls .owl-prev {
      left: 0px; }
      .subpage-carousel-controls .owl-prev i {
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
        padding-right: 50px;
        transition: background-color .3s ease, color .3s ease, padding .3s ease; }
        .subpage-carousel-controls .owl-prev i:hover {
          background-color: #435d73;
          color: #eb6e24;
          padding-left: 45px;
          transition: background-color .3s ease, color .3s ease, padding .3s ease; }
    .subpage-carousel-controls .owl-next {
      right: 0px; }
      .subpage-carousel-controls .owl-next i {
        border-top-left-radius: 35px;
        border-bottom-left-radius: 35px;
        padding-left: 50px;
        transition: background-color .3s ease, color .3s ease, padding .3s ease; }
        .subpage-carousel-controls .owl-next i:hover {
          background-color: #435d73;
          color: #eb6e24;
          padding-right: 45px;
          transition: background-color .3s ease, color .3s ease, padding .3s ease; }

/*
 *  ======================================= 
 *  VARIABLES | _variables
 *  =======================================  
 */
/*
 *  ======================================= 
 *  VIDEO | _video
 *  =======================================  
 */
.video {
  background-color: white;
  color: #252e48; }
  .video-header-title:before, .video-header-title:after {
    background-color: #252e48;
    height: 1.5px; }
  .video-main {
    margin-top: 45px; }
  .video-frame {
    background-color: rgba(143, 143, 143, 0.2); }
    .video-frame .iframecontent {
      padding: 30px;
      padding-right: 0; }
    .video-frame .content {
      padding: 30px;
      padding-left: 0; }
    .video-frame .backwardscontent {
      padding-left: 30px;
      padding-bottom: 30px; }
      .video-frame .backwardscontent a {
        color: #252e48; }
        .video-frame .backwardscontent a:hover {
          color: #eb6e24; }
    .video-frame .title {
      color: #eb6e24;
      font-size: 40px;
      margin-bottom: 15px; }
    .video-frame .description {
      color: #252e48;
      font-size: 16px;
      margin-bottom: 15px; }
    .video-frame .tags {
      font-size: 16px;
      padding-bottom: 30px;
      padding-left: 30px; }
    .video-frame .similarvideos {
      font-size: 16px;
      padding-bottom: 10px;
      padding-left: 30px;
      padding-right: 30px; }
      .video-frame .similarvideos-title {
        margin-bottom: 2px; }
    .video-frame .details {
      color: #567793; }
      .video-frame .details span {
        color: #252e48; }
      .video-frame .details-column {
        font-size: 16px;
        margin-bottom: 10px; }
    .video-frame .folders .bootstrap-select {
      margin-bottom: 20px; }
    .video-frame .folders .bootstrap-select.open {
      position: relative;
      z-index: 802; }
    .video-frame .folders .bootstrap-select .dropdown, .video-frame .folders .dropdown {
      margin-bottom: 20px; }
      .video-frame .folders .bootstrap-select .dropdown.open .dropdown-toggle, .video-frame .folders .dropdown.open .dropdown-toggle {
        position: relative;
        z-index: 801; }
      .video-frame .folders .bootstrap-select .dropdown-toggle, .video-frame .folders .dropdown-toggle {
        border-radius: 20px;
        padding: 9px 12px;
        width: 100%;
        z-index: 801 !important; }
        .video-frame .folders .bootstrap-select .dropdown-toggle:focus, .video-frame .folders .dropdown-toggle:focus {
          outline: 0 !important; }
        .video-frame .folders .bootstrap-select .dropdown-toggle .filter-option, .video-frame .folders .dropdown-toggle .filter-option {
          width: calc(100% - 12px) !important; }
      .video-frame .folders .bootstrap-select .dropdown-menu > li > a, .video-frame .folders .dropdown-menu > li > a {
        padding-left: 12px !important; }
    .video-frame .resolutions {
      width: 100%;
      margin-top: 15px;
      margin-bottom: 15px; }
      .video-frame .resolutions.nomargin-top {
        margin-top: 0; }
      .video-frame .resolutions th, .video-frame .resolutions td {
        font-size: 14px;
        text-align: center;
        padding: 5px; }
      .video-frame .resolutions th {
        background: #567793;
        color: white;
        font-weight: normal; }
      .video-frame .resolutions tr:nth-child(2n) td {
        background: #e4e4e4; }
      .video-frame .resolutions th:first-child, .video-frame .resolutions tr td:first-child {
        text-align: left; }
      .video-frame .resolutions td {
        border-bottom: 1px solid #cccccc; }
      .video-frame .resolutions .add-to-cart {
        font-size: 13px; }
      .video-frame .resolutions-info {
        border: 0;
        border-radius: 20px;
        color: #252e48;
        display: inline-block;
        font-size: 14px;
        font-weight: 400;
        overflow: hidden;
        padding: 9px 0;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        z-index: 0; }
    .video-frame .default-circlebutton {
      color: #252e48;
      height: auto;
      width: auto; }
      .video-frame .default-circlebutton i {
        transition: .2s ease-in-out; }
      .video-frame .default-circlebutton:before {
        background: none;
        border: none; }
      .video-frame .default-circlebutton:after {
        display: none; }
      .video-frame .default-circlebutton:hover i {
        font-size: 18px;
        color: #eb6e24; }
      .video-frame .default-circlebutton:hover:after {
        background: none;
        border: none; }
    .video-frame .orderbutton {
      margin-top: 45px; }
  .video .similarvideos .row {
    margin-left: -10px;
    margin-right: -10px; }
  .video .similarvideos [class*='col-'] {
    padding-left: 10px;
    padding-right: 10px; }
  .video .similarvideos .video-box {
    float: left;
    margin-bottom: 8px;
    margin-top: 15px;
    width: 100%; }
  .video .similarvideos .video-image {
    display: block;
    margin-bottom: 20px;
    overflow: hidden;
    position: relative; }
    .video .similarvideos .video-image:hover .more {
      color: white;
      transition: color .3s ease .1s; }
    .video .similarvideos .video-image:hover .image {
      opacity: 0.7;
      transform: scale(1.2); }
    .video .similarvideos .video-image .more {
      color: rgba(255, 255, 255, 0);
      font-size: 20px;
      left: 50%;
      position: absolute;
      text-transform: uppercase;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: color .3s ease;
      z-index: 1; }
    .video .similarvideos .video-image .image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: all .3s ease;
      position: absolute;
      height: 100%;
      width: 100%; }
    .video .similarvideos .video-image .ratio {
      background-color: #000000;
      padding-bottom: 55%; }
  .video .similarvideos .video-caption {
    position: absolute;
    top: 0;
    max-width: calc(100% - 30px); }
  .video .similarvideos .video-title {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    float: left;
    font-size: 13px;
    margin-top: 1px;
    padding: 2px 5px; }
  .video .similarvideos .video-details {
    float: right; }
    .video .similarvideos .video-details i {
      color: #eb6e24;
      margin-right: 2px; }

/*!
 * Bootstrap-select v1.12.4 (http://silviomoreto.github.io/bootstrap-select)
 *
 * Copyright 2013-2017 bootstrap-select
 * Licensed under MIT (https://github.com/silviomoreto/bootstrap-select/blob/master/LICENSE)
 */
select.bs-select-hidden,
select.selectpicker {
  display: none !important; }

.bootstrap-select {
  width: 220px \0;
  /*IE9 and below*/ }

.bootstrap-select > .dropdown-toggle {
  width: 100%;
  padding-right: 25px;
  z-index: 1; }

.bootstrap-select > .dropdown-toggle.bs-placeholder,
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #999; }

.bootstrap-select > select {
  position: absolute !important;
  bottom: 0;
  left: 50%;
  display: block !important;
  width: 0.5px !important;
  height: 100% !important;
  padding: 0 !important;
  opacity: 0 !important;
  border: none; }

.bootstrap-select > select.mobile-device {
  top: 0;
  left: 0;
  display: block !important;
  width: 100% !important;
  z-index: 2; }

.has-error .bootstrap-select .dropdown-toggle,
.error .bootstrap-select .dropdown-toggle {
  border-color: #b94a48; }

.bootstrap-select.fit-width {
  width: auto !important; }

.bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 220px; }

.bootstrap-select .dropdown-toggle:focus {
  outline: thin dotted #333333 !important;
  outline: 5px auto -webkit-focus-ring-color !important;
  outline-offset: -2px; }

.bootstrap-select.form-control {
  margin-bottom: 0;
  padding: 0;
  border: none; }

.bootstrap-select.form-control:not([class*="col-"]) {
  width: 100%; }

.bootstrap-select.form-control.input-group-btn {
  z-index: auto; }

.bootstrap-select.form-control.input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-radius: 0; }

.bootstrap-select.btn-group:not(.input-group-btn),
.bootstrap-select.btn-group[class*="col-"] {
  float: none;
  display: inline-block;
  margin-left: 0; }

.bootstrap-select.btn-group.dropdown-menu-right,
.bootstrap-select.btn-group[class*="col-"].dropdown-menu-right,
.row .bootstrap-select.btn-group[class*="col-"].dropdown-menu-right {
  float: right; }

.form-inline .bootstrap-select.btn-group,
.form-horizontal .bootstrap-select.btn-group,
.form-group .bootstrap-select.btn-group {
  margin-bottom: 0; }

.form-group-lg .bootstrap-select.btn-group.form-control,
.form-group-sm .bootstrap-select.btn-group.form-control {
  padding: 0; }

.form-group-lg .bootstrap-select.btn-group.form-control .dropdown-toggle,
.form-group-sm .bootstrap-select.btn-group.form-control .dropdown-toggle {
  height: 100%;
  font-size: inherit;
  line-height: inherit;
  border-radius: inherit; }

.form-inline .bootstrap-select.btn-group .form-control {
  width: 100%; }

.bootstrap-select.btn-group.disabled,
.bootstrap-select.btn-group > .disabled {
  cursor: not-allowed; }

.bootstrap-select.btn-group.disabled:focus,
.bootstrap-select.btn-group > .disabled:focus {
  outline: none !important; }

.bootstrap-select.btn-group.bs-container {
  position: absolute;
  height: 0 !important;
  padding: 0 !important; }

.bootstrap-select.btn-group.bs-container .dropdown-menu {
  z-index: 1060; }

.bootstrap-select.btn-group .dropdown-toggle .filter-option {
  display: inline-block;
  overflow: hidden;
  width: 100%;
  text-align: left; }

.bootstrap-select.btn-group .dropdown-toggle .caret {
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -2px;
  vertical-align: middle; }

.bootstrap-select.btn-group[class*="col-"] .dropdown-toggle {
  width: 100%; }

.bootstrap-select.btn-group .dropdown-menu {
  min-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select.btn-group .dropdown-menu.inner {
  position: static;
  float: none;
  border: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none; }

.bootstrap-select.btn-group .dropdown-menu li {
  position: relative; }

.bootstrap-select.btn-group .dropdown-menu li.active small {
  color: #fff; }

.bootstrap-select.btn-group .dropdown-menu li.disabled a {
  cursor: not-allowed; }

.bootstrap-select.btn-group .dropdown-menu li a {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.bootstrap-select.btn-group .dropdown-menu li a.opt {
  position: relative;
  padding-left: 2.25em; }

.bootstrap-select.btn-group .dropdown-menu li a span.check-mark {
  display: none; }

.bootstrap-select.btn-group .dropdown-menu li a span.text {
  display: inline-block; }

.bootstrap-select.btn-group .dropdown-menu li small {
  padding-left: 0.5em; }

.bootstrap-select.btn-group .dropdown-menu .notify {
  position: absolute;
  bottom: 5px;
  width: 96%;
  margin: 0 2%;
  min-height: 26px;
  padding: 3px 5px;
  background: #f5f5f5;
  border: 1px solid #e3e3e3;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  pointer-events: none;
  opacity: 0.9;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bootstrap-select.btn-group .no-results {
  padding: 3px;
  background: #f5f5f5;
  margin: 0 5px;
  white-space: nowrap; }

.bootstrap-select.btn-group.fit-width .dropdown-toggle .filter-option {
  position: static; }

.bootstrap-select.btn-group.fit-width .dropdown-toggle .caret {
  position: static;
  top: auto;
  margin-top: -1px; }

.bootstrap-select.btn-group.show-tick .dropdown-menu li.selected a span.check-mark {
  position: absolute;
  display: inline-block;
  right: 15px;
  margin-top: 5px; }

.bootstrap-select.btn-group.show-tick .dropdown-menu li a span.text {
  margin-right: 34px; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle {
  z-index: 1061; }

.bootstrap-select.show-menu-arrow .dropdown-toggle:before {
  content: '';
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid rgba(204, 204, 204, 0.2);
  position: absolute;
  bottom: -4px;
  left: 9px;
  display: none; }

.bootstrap-select.show-menu-arrow .dropdown-toggle:after {
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  bottom: -4px;
  left: 10px;
  display: none; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:before {
  bottom: auto;
  top: -3px;
  border-top: 7px solid rgba(204, 204, 204, 0.2);
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.dropup .dropdown-toggle:after {
  bottom: auto;
  top: -3px;
  border-top: 6px solid white;
  border-bottom: 0; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:before {
  right: 12px;
  left: auto; }

.bootstrap-select.show-menu-arrow.pull-right .dropdown-toggle:after {
  right: 13px;
  left: auto; }

.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:before,
.bootstrap-select.show-menu-arrow.open > .dropdown-toggle:after {
  display: block; }

.bs-searchbox,
.bs-actionsbox,
.bs-donebutton {
  padding: 4px 8px; }

.bs-actionsbox {
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-actionsbox .btn-group button {
  width: 50%; }

.bs-donebutton {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.bs-donebutton .btn-group button {
  width: 100%; }

.bs-searchbox + .bs-actionsbox {
  padding: 0 8px 4px; }

.bs-searchbox .form-control {
  margin-bottom: 0;
  width: 100%;
  float: none; }

/*# sourceMappingURL=bootstrap-select.css.map */
/*
 * bootstrap-tagsinput v0.8.0
 *
 */
.bootstrap-tagsinput {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  display: inline-block;
  padding: 4px 6px;
  color: #555;
  vertical-align: middle;
  border-radius: 4px;
  max-width: 100%;
  line-height: 22px;
  cursor: text; }

.bootstrap-tagsinput input {
  border: none;
  box-shadow: none;
  outline: none;
  background-color: transparent;
  margin: 0;
  width: auto;
  max-width: inherit; }

.bootstrap-tagsinput.form-control input::-moz-placeholder {
  color: #777;
  opacity: 1; }

.bootstrap-tagsinput.form-control input:-ms-input-placeholder {
  color: #777; }

.bootstrap-tagsinput.form-control input::-webkit-input-placeholder {
  color: #777; }

.bootstrap-tagsinput input:focus {
  border: none;
  box-shadow: none; }

.bootstrap-tagsinput .tag, .popover .tag {
  margin-right: 2px;
  color: white; }

.bootstrap-tagsinput .tag [data-role="remove"], .popover .tag [data-role="remove"] {
  margin-left: 8px;
  cursor: pointer; }

.bootstrap-tagsinput .tag [data-role="remove"]:after, .popover .tag [data-role="remove"]:after {
  content: "x";
  padding: 0px 2px; }

.bootstrap-tagsinput .tag [data-role="remove"]:hover, .popover .tag [data-role="remove"]:hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05); }

.bootstrap-tagsinput .tag [data-role="remove"]:hover:active, .popover .tag [data-role="remove"]:hover:active {
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

/*
 * Author: Digital Zoom Studio
 * Website: http://digitalzoomstudio.net/
 * Portfolio: http://codecanyon.net/user/ZoomIt/portfolio
 *
 * Version: 1.30
 *
 */
@-webkit-keyframes keyframes-rotate {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg); }
  50% {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg); }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg); } }

@keyframes keyframes-rotate {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg); }
  50% {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg); }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg); } }

.dzsparallaxer {
  height: 300px;
  overflow: hidden;
  background-color: #1c1a17;
  position: relative;
  transition-property: height;
  transition-duration: 0.3s;
  -moz-transition-property: height;
  -moz-transition-duration: 0.3s;
  -webkit-transition-property: height;
  -webkit-transition-duration: 0.3s;
  -o-transition-property: height;
  -o-transition-duration: 0.3s;
  -ms-transition-property: height;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out-quart;
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }

.dzsparallaxer .dzsparallaxer--target {
  position: relative; }

.dzsparallaxer .dzsparallaxer--target.position-absolute {
  position: absolute;
  top: 0;
  left: 0; }

.dzsparallaxer.wait-readyall.dzsprx-readyall .dzsparallaxer--target {
  opacity: 1; }

.dzsparallaxer.use-loading .dzsparallaxer--target {
  opacity: 0;
  transition-property: opacity, visibility;
  transition-duration: 0.7s;
  -moz-transition-property: opacity, visibility;
  -moz-transition-duration: 0.7s;
  -webkit-transition-property: opacity, visibility;
  -webkit-transition-duration: 0.7s;
  -o-transition-property: opacity, visibility;
  -o-transition-duration: 0.7s;
  -ms-transition-property: opacity, visibility;
  -ms-transition-duration: 0.7s;
  -ms-transition-timing-function: ease-out-quart;
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }

.dzsparallaxer.use-loading .preloader-semicircles {
  transition-property: all;
  transition-duration: 0.3s;
  -moz-transition-property: all;
  -moz-transition-duration: 0.3s;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -o-transition-property: all;
  -o-transition-duration: 0.3s;
  -ms-transition-property: all;
  -ms-transition-duration: 0.3s;
  -ms-transition-timing-function: ease-out-quart;
  -webkit-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  -moz-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  -o-transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1); }

.dzsparallaxer.use-loading.loaded .dzsparallaxer--target {
  opacity: 1; }

.dzsparallaxer.use-loading.loaded .preloader-semicircles {
  transform: translate3d(0, -50px, 0);
  opacity: 0;
  visibility: hidden; }

.dzsparallaxer.allbody {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0; }

.divimage {
  background-size: cover;
  background-position: center center; }

/*# sourceMappingURL=dzsparallaxer.css.map */
/*
== malihu jquery custom scrollbar plugin ==
Plugin URI: http://manos.malihu.gr/jquery-custom-content-scroller
*/
/*
CONTENTS: 
	1. BASIC STYLE - Plugin's basic/essential CSS properties (normally, should not be edited). 
	2. VERTICAL SCROLLBAR - Positioning and dimensions of vertical scrollbar. 
	3. HORIZONTAL SCROLLBAR - Positioning and dimensions of horizontal scrollbar.
	4. VERTICAL AND HORIZONTAL SCROLLBARS - Positioning and dimensions of 2-axis scrollbars. 
	5. TRANSITIONS - CSS3 transitions for hover events, auto-expanded and auto-hidden scrollbars. 
	6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS 
		6.1 THEMES - Scrollbar colors, opacity, dimensions, backgrounds etc. via ready-to-use themes.
*/
/* 
------------------------------------------------------------------------------------------------------------------------
1. BASIC STYLE  
------------------------------------------------------------------------------------------------------------------------
*/
.mCustomScrollbar {
  -ms-touch-action: pinch-zoom;
  touch-action: pinch-zoom;
  /* direct pointer events to js */ }

.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action {
  -ms-touch-action: auto;
  touch-action: auto; }

.mCustomScrollBox {
  /* contains plugin's markup */
  position: relative;
  overflow: hidden;
  height: 100%;
  max-width: 100%;
  outline: none;
  direction: ltr; }

.mCSB_container {
  /* contains the original content */
  overflow: hidden;
  width: auto;
  height: auto; }

/* 
------------------------------------------------------------------------------------------------------------------------
2. VERTICAL SCROLLBAR 
y-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_inside > .mCSB_container {
  /*margin-right: 30px;*/ }

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0; }

/* non-visible scrollbar */
.mCS-dir-rtl > .mCSB_inside > .mCSB_container {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-left: 0; }

/* RTL direction/left-side scrollbar */
.mCSB_scrollTools {
  /* contains scrollbar markup (draggable element, dragger rail, buttons etc.) */
  position: absolute;
  width: 16px;
  height: auto;
  left: auto;
  top: 0;
  right: 0;
  bottom: 0; }

.mCSB_outside + .mCSB_scrollTools {
  right: -26px; }

/* scrollbar position: outside */
.mCS-dir-rtl > .mCSB_inside > .mCSB_scrollTools,
.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  /* RTL direction/left-side scrollbar */
  right: auto;
  left: 0; }

.mCS-dir-rtl > .mCSB_outside + .mCSB_scrollTools {
  left: -26px; }

/* RTL direction/left-side scrollbar (scrollbar position: outside) */
.mCSB_scrollTools .mCSB_draggerContainer {
  /* contains the draggable element and dragger rail markup */
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: auto; }

.mCSB_scrollTools a + .mCSB_draggerContainer {
  margin: 20px 0; }

.mCSB_scrollTools .mCSB_draggerRail {
  width: 2px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCSB_scrollTools .mCSB_dragger {
  /* the draggable element */
  cursor: pointer;
  width: 100%;
  height: 30px;
  /* minimum dragger height */
  z-index: 1; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  /* the dragger element */
  position: relative;
  width: 4px;
  height: 100%;
  margin: 0 auto;
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  text-align: center; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 12px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 8px;
  /* auto-expanded scrollbar */ }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown {
  display: block;
  position: absolute;
  height: 20px;
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools .mCSB_buttonDown {
  bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
3. HORIZONTAL SCROLLBAR 
x-axis
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-right: 0;
  margin-bottom: 30px; }

.mCSB_horizontal.mCSB_outside > .mCSB_container {
  min-height: 100%; }

.mCSB_horizontal > .mCSB_container.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* non-visible scrollbar */
.mCSB_scrollTools.mCSB_scrollTools_horizontal {
  width: auto;
  height: 16px;
  top: auto;
  right: 0;
  bottom: 0;
  left: 0; }

.mCustomScrollBox + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: -26px; }

/* scrollbar position: outside */
.mCSB_scrollTools.mCSB_scrollTools_horizontal a + .mCSB_draggerContainer {
  margin: 0 20px; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 2px;
  margin: 7px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 30px;
  /* minimum dragger width */
  height: 100%;
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  /* auto-expanded scrollbar */
  margin: 2px auto; }

.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 8px;
  /* auto-expanded scrollbar */
  margin: 4px 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft,
.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  display: block;
  position: absolute;
  width: 20px;
  height: 100%;
  overflow: hidden;
  margin: 0 auto;
  cursor: pointer; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonLeft {
  left: 0; }

.mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_buttonRight {
  right: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
4. VERTICAL AND HORIZONTAL SCROLLBARS 
yx-axis 
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_container_wrapper {
  position: absolute;
  height: auto;
  width: auto;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin-right: 30px;
  margin-bottom: 30px; }

.mCSB_container_wrapper > .mCSB_container {
  padding-right: 30px;
  padding-bottom: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 20px; }

.mCSB_vertical_horizontal > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 20px; }

/* non-visible horizontal scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden + .mCSB_scrollTools.mCSB_scrollTools_vertical {
  bottom: 0; }

/* non-visible vertical scrollbar/RTL direction/left-side scrollbar */
.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  right: 0; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 20px; }

/* non-visible scrollbar/RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden + .mCSB_scrollTools ~ .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  left: 0; }

.mCS-dir-rtl > .mCSB_inside > .mCSB_container_wrapper {
  /* RTL direction/left-side scrollbar */
  margin-right: 0;
  margin-left: 30px; }

.mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden > .mCSB_container {
  padding-right: 0; }

.mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden > .mCSB_container {
  padding-bottom: 0; }

.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_y.mCS_y_hidden {
  margin-right: 0;
  /* non-visible scrollbar */
  margin-left: 0; }

/* non-visible horizontal scrollbar */
.mCustomScrollBox.mCSB_vertical_horizontal.mCSB_inside > .mCSB_container_wrapper.mCS_no_scrollbar_x.mCS_x_hidden {
  margin-bottom: 0; }

/* 
------------------------------------------------------------------------------------------------------------------------
5. TRANSITIONS  
------------------------------------------------------------------------------------------------------------------------
*/
.mCSB_scrollTools,
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  -webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: opacity .2s ease-in-out, background-color .2s ease-in-out; }

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail {
  -webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -moz-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  -o-transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out;
  transition: width .2s ease-out .2s, height .2s ease-out .2s,  margin-left .2s ease-out .2s, margin-right .2s ease-out .2s,  margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s, opacity .2s ease-in-out, background-color .2s ease-in-out; }

/* 
------------------------------------------------------------------------------------------------------------------------
6. SCROLLBAR COLORS, OPACITY AND BACKGROUNDS  
------------------------------------------------------------------------------------------------------------------------
*/
/* 
	----------------------------------------
	6.1 THEMES 
	----------------------------------------
	*/
/* default theme ("light") */
.mCSB_scrollTools {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCS-autoHide > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 0;
  filter: "alpha(opacity=0)";
  -ms-filter: "alpha(opacity=0)"; }

.mCustomScrollbar > .mCustomScrollBox > .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollbar > .mCustomScrollBox ~ .mCSB_scrollTools.mCSB_scrollTools_onDrag,
.mCustomScrollBox:hover > .mCSB_scrollTools,
.mCustomScrollBox:hover ~ .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox > .mCSB_scrollTools,
.mCS-autoHide:hover > .mCustomScrollBox ~ .mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=100)";
  -ms-filter: "alpha(opacity=100)"; }

.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85);
  filter: "alpha(opacity=85)";
  -ms-filter: "alpha(opacity=85)"; }

.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9);
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight {
  background-image: url(mCSB_buttons.png);
  /* css sprites */
  background-repeat: no-repeat;
  opacity: 0.4;
  filter: "alpha(opacity=40)";
  -ms-filter: "alpha(opacity=40)"; }

.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 0;
  /* 
		sprites locations 
		light: 0 0, -16px 0, -32px 0, -48px 0, 0 -72px, -16px -72px, -32px -72px
		dark: -80px 0, -96px 0, -112px 0, -128px 0, -80px -72px, -96px -72px, -112px -72px
		*/ }

.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -20px;
  /* 
		sprites locations
		light: 0 -20px, -16px -20px, -32px -20px, -48px -20px, 0 -92px, -16px -92px, -32px -92px
		dark: -80px -20px, -96px -20px, -112px -20px, -128px -20px, -80px -92px, -96px -92px, -112 -92px
		*/ }

.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -40px;
  /* 
		sprites locations 
		light: 0 -40px, -20px -40px, -40px -40px, -60px -40px, 0 -112px, -20px -112px, -40px -112px
		dark: -80px -40px, -100px -40px, -120px -40px, -140px -40px, -80px -112px, -100px -112px, -120px -112px
		*/ }

.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -56px;
  /* 
		sprites locations 
		light: 0 -56px, -20px -56px, -40px -56px, -60px -56px, 0 -128px, -20px -128px, -40px -128px
		dark: -80px -56px, -100px -56px, -120px -56px, -140px -56px, -80px -128px, -100px -128px, -120px -128px
		*/ }

.mCSB_scrollTools .mCSB_buttonUp:hover,
.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonLeft:hover,
.mCSB_scrollTools .mCSB_buttonRight:hover {
  opacity: 0.75;
  filter: "alpha(opacity=75)";
  -ms-filter: "alpha(opacity=75)"; }

.mCSB_scrollTools .mCSB_buttonUp:active,
.mCSB_scrollTools .mCSB_buttonDown:active,
.mCSB_scrollTools .mCSB_buttonLeft:active,
.mCSB_scrollTools .mCSB_buttonRight:active {
  opacity: 0.9;
  filter: "alpha(opacity=90)";
  -ms-filter: "alpha(opacity=90)"; }

/* theme: "dark" */
.mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme: "light-2", "dark-2" */
.mCS-light-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-light-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 4px;
  margin: 6px auto; }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px 0; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -20px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -40px; }

.mCS-light-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -56px; }

/* theme: "dark-2" */
.mCS-dark-2.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 1px;
  -moz-border-radius: 1px;
  border-radius: 1px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-2.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-2.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px 0; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -20px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -40px; }

.mCS-dark-2.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -56px; }

/* ---------------------------------------- */
/* theme: "light-thick", "dark-thick" */
.mCS-light-thick.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  width: 4px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 4px;
  margin: 6px 0; }

.mCS-light-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 6px;
  margin: 5px auto; }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-light-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-light-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px 0; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -20px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -40px; }

.mCS-light-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -56px; }

/* theme: "dark-thick" */
.mCS-dark-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px 0; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -20px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -40px; }

.mCS-dark-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -56px; }

/* ---------------------------------------- */
/* theme: "light-thin", "dark-thin" */
.mCS-light-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.1); }

.mCS-light-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 2px; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%; }

.mCS-light-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 2px;
  margin: 7px auto; }

/* theme "dark-thin" */
.mCS-dark-thin.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-thin.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px 0; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -20px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -40px; }

.mCS-dark-thin.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -56px; }

/* ---------------------------------------- */
/* theme "rounded", "rounded-dark", "rounded-dots", "rounded-dots-dark" */
.mCS-rounded.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.15); }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger {
  height: 14px; }

.mCS-rounded.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 14px;
  margin: 0 1px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 14px; }

.mCS-rounded.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 14px;
  margin: 1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  width: 16px;
  /* auto-expanded scrollbar */
  height: 16px;
  margin: -1px 0; }

.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 4px;
  /* auto-expanded scrollbar */ }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar {
  height: 16px;
  /* auto-expanded scrollbar */
  width: 16px;
  margin: 0 -1px; }

.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-rounded-dark.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 4px;
  /* auto-expanded scrollbar */
  margin: 6px 0; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonUp {
  background-position: 0 -72px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonDown {
  background-position: 0 -92px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: 0 -112px; }

.mCS-rounded.mCSB_scrollTools .mCSB_buttonRight {
  background-position: 0 -128px; }

/* theme "rounded-dark", "rounded-dots-dark" */
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.15); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -80px -72px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -80px -92px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -80px -112px; }

.mCS-rounded-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -80px -128px; }

/* theme "rounded-dots", "rounded-dots-dark" */
.mCS-rounded-dots.mCSB_scrollTools_vertical .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_vertical .mCSB_draggerRail {
  width: 4px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: transparent;
  background-position: center; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYV2NkIAAYiVbw//9/Y6DiM1ANJoyMjGdBbLgJQAX/kU0DKgDLkaQAvxW4HEvQFwCRcxIJK1XznAAAAABJRU5ErkJggg==");
  background-repeat: repeat-y;
  opacity: 0.3;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-rounded-dots.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-rounded-dots-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  height: 4px;
  margin: 6px 0;
  background-repeat: repeat-x; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -16px -72px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -16px -92px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -20px -112px; }

.mCS-rounded-dots.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -20px -128px; }

/* theme "rounded-dots-dark" */
.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAALElEQVQYV2NkIAAYSVFgDFR8BqrBBEifBbGRTfiPZhpYjiQFBK3A6l6CvgAAE9kGCd1mvgEAAAAASUVORK5CYII="); }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -96px -72px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -96px -92px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -100px -112px; }

.mCS-rounded-dots-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -100px -128px; }

/* ---------------------------------------- */
/* theme "3d", "3d-dark", "3d-thick", "3d-thick-dark" */
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-y;
  background-image: -moz-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  background-repeat: repeat-x;
  background-image: -moz-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(255, 255, 255, 0.5)), color-stop(100%, rgba(255, 255, 255, 0)));
  background-image: -webkit-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: -ms-linear-gradient(top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%); }

/* theme "3d", "3d-dark" */
.mCS-3d.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 70px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 70px; }

.mCS-3d.mCSB_scrollTools,
.mCS-3d-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px; }

.mCS-3d.mCSB_scrollTools .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 8px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.5), inset -1px 0 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 8px; }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 8px;
  margin: 4px 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.5), inset 0 -1px 1px rgba(255, 255, 255, 0.2); }

.mCS-3d.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  width: 100%;
  height: 8px;
  margin: 4px auto; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "3d-dark" */
.mCS-3d-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1);
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1); }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "3d-thick", "3d-thick-dark" */
.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools {
  opacity: 1;
  filter: "alpha(opacity=30)";
  -ms-filter: "alpha(opacity=30)"; }

.mCS-3d-thick.mCSB_scrollTools,
.mCS-3d-thick-dark.mCSB_scrollTools,
.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }

.mCSB_inside + .mCS-3d-thick.mCSB_scrollTools_vertical,
.mCSB_inside + .mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  right: 1px; }

.mCS-3d-thick.mCSB_scrollTools_vertical,
.mCS-3d-thick-dark.mCSB_scrollTools_vertical {
  box-shadow: inset 1px 0 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools_horizontal,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  bottom: 1px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.5); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4);
  width: 12px;
  margin: 2px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #555; }

.mCS-3d-thick.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 12px;
  width: auto; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-3d-thick.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme: "3d-thick-dark" */
.mCS-3d-thick-dark.mCSB_scrollTools {
  box-shadow: inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.1), inset 0 0 14px rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 1px 0 0 rgba(255, 255, 255, 0.4), inset -1px 0 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.2); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #777; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerContainer {
  background-color: #fff;
  background-color: rgba(0, 0, 0, 0.05);
  box-shadow: inset 1px 1px 16px rgba(0, 0, 0, 0.1); }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-3d-thick-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme: "minimal", "minimal-dark" */
.mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  right: 0;
  margin: 12px 0; }

.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.mCustomScrollBox.mCS-minimal-dark + .mCSB_scrollTools + .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  bottom: 0;
  margin: 0 12px; }

/* RTL direction/left-side scrollbar */
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal.mCSB_scrollTools_vertical,
.mCS-dir-rtl > .mCSB_outside + .mCS-minimal-dark.mCSB_scrollTools_vertical {
  left: 0;
  right: auto; }

.mCS-minimal.mCSB_scrollTools .mCSB_draggerRail,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent; }

.mCS-minimal.mCSB_scrollTools_vertical .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_vertical .mCSB_dragger {
  height: 50px; }

.mCS-minimal.mCSB_scrollTools_horizontal .mCSB_dragger,
.mCS-minimal-dark.mCSB_scrollTools_horizontal .mCSB_dragger {
  width: 50px; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* theme: "minimal-dark" */
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  filter: "alpha(opacity=20)";
  -ms-filter: "alpha(opacity=20)"; }

.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-minimal-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
  filter: "alpha(opacity=50)";
  -ms-filter: "alpha(opacity=50)"; }

/* ---------------------------------------- */
/* theme "light-3", "dark-3" */
.mCS-light-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  width: 6px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-light-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px; }

.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-light-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 6px;
  margin: 5px 0; }

.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  width: 12px; }

.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-light-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail,
.mCS-dark-3.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail {
  height: 12px;
  margin: 2px 0; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-light-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "dark-3" */
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-dark-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-dark-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-dark-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-dark-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* ---------------------------------------- */
/* theme "inset", "inset-dark", "inset-2", "inset-2-dark", "inset-3", "inset-3-dark" */
.mCS-inset.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  width: 12px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.2); }

.mCS-inset.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 6px;
  margin: 3px 5px;
  position: absolute;
  height: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 6px;
  margin: 5px 3px;
  position: absolute;
  width: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

.mCS-inset.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  width: 100%;
  height: 12px;
  margin: 2px 0; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -32px -72px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -32px -92px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -40px -112px; }

.mCS-inset.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -40px -128px; }

/* theme "inset-dark", "inset-2-dark", "inset-3-dark" */
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.1); }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonUp,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonUp {
  background-position: -112px -72px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonDown,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonDown {
  background-position: -112px -92px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonLeft,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonLeft {
  background-position: -120px -112px; }

.mCS-inset-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_buttonRight,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_buttonRight {
  background-position: -120px -128px; }

/* theme "inset-2", "inset-2-dark" */
.mCS-inset-2.mCSB_scrollTools .mCSB_draggerRail,
.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  border-color: rgba(255, 255, 255, 0.2);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.mCS-inset-2-dark.mCSB_scrollTools .mCSB_draggerRail {
  border-color: #000;
  border-color: rgba(0, 0, 0, 0.2); }

/* theme "inset-3", "inset-3-dark" */
.mCS-inset-3.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.6); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.6); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.75); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.85); }

.mCS-inset-3.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.9); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.75); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.85); }

.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-inset-3-dark.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.9); }

/* ---------------------------------------- */
/**
 * Owl Carousel v2.3.0
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */ }

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0; }

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px); }

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0); }

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; }

.owl-carousel .owl-item img {
  display: block;
  width: 100%; }

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none; }

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit; }

.owl-carousel.owl-loaded {
  display: block; }

.owl-carousel.owl-loading {
  opacity: 0;
  display: block; }

.owl-carousel.owl-hidden {
  opacity: 0; }

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden; }

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: none;
  touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab; }

.owl-carousel.owl-rtl {
  direction: rtl; }

.owl-carousel.owl-rtl .owl-item {
  float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut; }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: -webkit-transform 100ms ease;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -webkit-transform: scale(1.3, 1.3);
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }
