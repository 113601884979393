/*
 *  ======================================= 
 *  VIDEO | _video
 *  =======================================  
 */

.video{
	background-color: $white;
	color: $blue;
	&-header{
		&-title{
			&:before, &:after{
				background-color: $blue;
				height: 1.5px;
			}
		}
	}
	
	&-main{
		margin-top: 45px;
	}
	
	&-frame{
		background-color: rgba($grey2, 0.2);
		.iframecontent{
			padding: 30px;
			padding-right: 0;
		}
		.content{
			padding: 30px;
			padding-left: 0;
		}
		.backwardscontent{
			padding-left: 30px;
			padding-bottom: 30px;
			a{
				color: $blue;
				&:hover{
					color: $orange;
				}
			}
		}
		.title{
			color: $orange;
			font-size: 40px;
			margin-bottom: 15px;
		}
		.description{
			color: $blue;
			font-size: 16px;
			margin-bottom: 15px;
		}
		.tags{
			font-size: 16px;
			padding-bottom: 30px;
			padding-left: 30px;
			//margin-bottom: 10px;
		}
		.similarvideos{
			font-size: 16px;
			padding-bottom: 10px;
			padding-left: 30px;
			padding-right: 30px;
			&-title{
				margin-bottom: 2px;
			}
		}
		.details{
			color: $blue2;
			span{
				color: $blue;
			}
			&-column{
				font-size: 16px;
				margin-bottom: 10px;
			}
		}
		.folders{
			.bootstrap-select{
				margin-bottom: 20px;
			}
			.bootstrap-select.open{
				position: relative;
				z-index: 802;
			}
			.bootstrap-select .dropdown, .dropdown{
				margin-bottom: 20px;
				&.open{
					.dropdown-toggle{
						position: relative;
						z-index: 801;
					}
				}
				&-toggle{
					border-radius: 20px;
					padding: 9px 12px;
					width: 100%;
					z-index: 801 !important;
					&:focus{
						outline: 0 !important;
					}
					.filter-option{
						width: calc(100% - 12px) !important;
					}
				}
				&-menu{
					//padding-top: 20px;
					//top: calc(100% - 20px);
					//width: 100%;
					>li>a{
						padding-left: 12px !important;
					}
				}
			}
		}
		.resolutions{
			width: 100%;
			margin-top: 15px;
			margin-bottom: 15px;
			&.nomargin-top{
				margin-top: 0;
			}
			th, td{
				font-size: 14px;
				text-align: center;
				padding: 5px;
			}
			th{
				background: $blue2;
				color: $white;
				font-weight: normal;
			}
			tr:nth-child(2n) td {
				background: #e4e4e4;
			}
			th:first-child, tr td:first-child {
				text-align: left;
			}
			td {
				border-bottom: 1px solid #cccccc;
			}
			.add-to-cart{
				font-size: 13px;
			}
			&-info{
				border: 0;
				border-radius: 20px;
				color: $blue;
				display: inline-block;
				font-size: 14px;
				font-weight: 400;
				overflow: hidden;
				padding: 9px 0;
				position: relative;
				text-align: center;
				text-transform: uppercase;
				z-index: 0;
			}
		}
		.default-circlebutton{
			color: $blue;
			height: auto;
			//padding: 0;
			width: auto;
			i{
				transition: .2s ease-in-out;
			}
			&:before{
				background: none;
				border: none;
			}
			&:after{
				display: none;
			}
			&:hover{
				i{
					font-size: 18px;
					color: $orange;
				}
				&:after{
					background: none;
					border: none;
				}
			}						
		}
		.orderbutton{
			margin-top: 45px;
		}
	}
	
	.similarvideos{
		.row{
			margin-left: -10px;
			margin-right: -10px;
		}
		[class*='col-']{
			padding-left: 10px;
			padding-right: 10px;
		}
		.video{
			&-box{
				float: left;
				margin-bottom: 8px;
				margin-top: 15px;
				width: 100%;
			}
			&-image{
				display: block;
				margin-bottom: 20px;
				overflow: hidden;
				position: relative;
				&:hover{
					.more{
						color: rgba($white, 1);
						transition: color .3s ease .1s;
					}
					.image{
						opacity: 0.7;
						transform: scale(1.2);
					}
				}
				.more{
					color: rgba($white, 0);
					font-size: 20px;
					left: 50%;
					position: absolute;
					text-transform: uppercase;
					top: 50%;
					transform: translate(-50%, -50%);
					transition: color .3s ease;
					z-index: 1;
				}
				.image{
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
					transition: all .3s ease;
					position: absolute;
					height: 100%;
					width: 100%;
				}
				.ratio{
					background-color: $black;
					padding-bottom: 55%;
				}
			}
			&-caption{
				position: absolute;
				top: 0;
				max-width: calc(100% - 30px);
			}
			&-title{
				background-color: rgba($black,0.5);
				color: $white;
				float: left;
				font-size: 13px;
				margin-top: 1px;
				padding: 2px 5px;
			}
			&-details{
				float: right;
				i{
					color: $orange;
					margin-right: 2px;
				}
			}
		}
	}
}