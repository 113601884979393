/*
 *  ======================================= 
 *  CART | _cart
 *  =======================================  
 */

.cart{
	background-color: $white;
	color: $blue;
	&-header{
		&-title{
			color: $orange;
			font-size: 40px;
			max-width: 100% !important;
			padding: 50px 0 27.5px;
			text-align: left;
			&:before, &:after{
				display: none;
			}
		}
		&-nav{
			padding: 45px 0 22.5px;
			text-align: right;
			.folder{
				display: inline-block;
				height: 25px;
				margin: 12.5px 0;
				vertical-align: middle;
			}
			.action{
				color: $blue;
				display: inline-block;
				height: 25px;
				margin: 12.5px 0;
				vertical-align: middle;
				i{
					vertical-align: text-bottom;
				}
				&:hover{
					color: $orange;
				}
				&:first-child{
					border-right: 1px solid $grey;
					padding-right: 12.5px;
				}
				&:last-child{
					padding-left: 12.5px;
				}
			}
		}
	}
	
	&-options{
		margin-bottom: 30px;
		.row{
			margin-left: -5px;
			margin-right: -5px;
		}
		.optioncolumn{
			padding-left: 5px;
			padding-right: 5px;
		}
		.dropdown{
			margin-bottom: 20px;
			&.open{
				.dropdown-toggle{
					position: relative;
					z-index: 1001;
				}
			}
			&-toggle{
				border-radius: 20px;
				padding: 9px 12px;
				width: 100%;
			}
			&-menu{
				padding-top: 20px;
				top: calc(100% - 20px);
				width: 100%;
				>li>a{
					padding: 3px 13px;
				}
			}
		}
		.title{
			display: inline-block;
			text-align: left;
			width: calc(100% - 8px);
		}
		.optionbutton{
			margin-bottom: 20px;
			min-width: initial;
			width: 100%;
		}
		.bordercolumn{
			position: relative;
			&:before{
				background-color: $grey;
				content: '';
				height: 14px;
				position: absolute;
				left: 0;
				top: 12px;
				width: 1px;
			}
		}
		.price{
			color: $orange;
			margin-bottom: 20px;
			padding: 9px 0;
		}
	}
	
	&-products{
		.row{
			margin-left: -10px;
			margin-right: -10px;
		}
		.categorycolumn{
			display: -webkit-flex;
			margin-bottom: 20px;
			padding-left: 10px;
			padding-right: 10px;
		}
		.categorybox{
			background-color: rgba($grey2, 0.2);
			float: left;
			height: 100%;
			padding-left: 15px;
			padding-right: 15px;
			width: 100%;
		}
		.title{
			color: $blue3;
			font-size: 24px;
			padding-top: 20px;
		}
		.video{
			&-box{
				float: left;
				margin-bottom: 8px;
				margin-top: 15px;
				width: 100%;
			}
			&-image{
				display: block;
				margin-bottom: 10px;
				overflow: hidden;
				position: relative;
				&:hover{
					.more{
						color: rgba($white, 1);
						transition: color .3s ease .1s;
					}
					.image{
						opacity: 0.7;
						transform: scale(1.2);
					}
				}
				.more{
					color: rgba($white, 0);
					font-size: 20px;
					left: 50%;
					position: absolute;
					text-transform: uppercase;
					top: 50%;
					transform: translate(-50%, -50%);
					transition: color .3s ease;
					z-index: 1;
				}
				.image{
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
					transition: all .3s ease;
					position: absolute;
					height: 100%;
					width: 100%;
				}
				.ratio{
					background-color: $black;
					padding-bottom: 55%;
				}
			}
			&-caption{
				float: left;
				position: relative;
				top: 2px;
				width: 100%;
			}
			&-title{
				color: lighten($black, 30%);
				float: left;
				font-size: 13px;
				margin-bottom: 10px;
			}
			&-details{
				float: right;
				margin-bottom: 10px;
				i{
					color: $orange;
					float: left;
					margin-right: 5px;
				}
			}
			&-time{
				display: inline-block;
				float: left;
				margin-right: 10px;
				&-value{
					color: $black;
					float: left;
					font-size: 13px;
				}
			}
			&-resolution{
				display: inline-block;
				float: left;
				&-value{
					color: $black;
					float: left;
					font-size: 13px;
				}
			}
			&-description{
				display: none;
				float: left;
				margin-bottom: 10px;
				width: 100%;
			}
			&-price{
				color: $black;
				float: right;
				font-size: 16px;
				margin-top: 8px;
				margin-bottom: 10px;
				text-align: right;
				//width: 100%;
			}
			&-comment{
				float: left;
				font-size: 16px;
				font-style: italic;
				text-align: justify;
				width: 100%;
			}
			&-buttons{
				float: left;
				text-align: right;
				//width: 100%;
				a, button{
					margin-right: 15px;
				}
			}
		}
	}
}


_:-moz-tree-row(hover), .cart-products .categorybox {
	line-height: 1.2;
	padding-bottom: 3px;
}